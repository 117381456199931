<div class="alert alert-warning alert-dismissible {{ mostrar }}" role="alert">
    <strong>Holy guacamole!</strong> You should check in on some of those fields below.
    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>


<ng-template #content let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Profile update</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form>
        <div class="form-group">
          <label for="dateOfBirth">Date of birth</label>
          <div class="input-group">
            <input id="dateOfBirth" class="form-control" placeholder="yyyy-mm-dd" name="dp">
            
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Save</button>
    </div>
  </ng-template>
  
  <button class="btn btn-lg btn-outline-primary" (click)="open(content)">Launch demo modal</button>
  
  <hr>
  
  <pre>{{ closeResult }}</pre>