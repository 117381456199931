<div class="account-pages my-5 pt-5">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="text-center mb-5">
                    <h1 class="display-2 fw-medium">5<i class="bx bx-buoy bx-spin color-banco display-3"></i>3</h1>
                    <h4 class="text-uppercase">Servicio no disponible</h4>
                    <div class="mt-5 text-center">
                        <a class="btn btn-danger btn-banco" routerLink="/">Regresar a inicio</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>