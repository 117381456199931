<!DOCTYPE html >
<html>
<head>
<meta http-equiv="X-UA-Compatible" content="IE=Edge" />
<meta charset="utf-8" />
</head>

<body style="margin: 0;"  >

<div id="p1" style="overflow: hidden; position: relative; background-color: white; width: 936px; height: 1429px;">

<!-- Begin shared CSS values -->
<style class="shared-css" type="text/css" >
.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}

.teeee {
	
	position: absolute;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}
</style>
<!-- End shared CSS values -->


<!-- Begin inline CSS -->
<style type="text/css" >

#t1_1{left:415px;bottom:784px;letter-spacing:0.19px;word-spacing:6.23px;}

#t2_1{left:35px;bottom:764px;letter-spacing:0.13px;}
#sidons{left:420px;bottom:763px;letter-spacing:0.13px;}
#nodons{left:449px;bottom:763px;letter-spacing:0.13px;} 

#t3_1{left:55px;bottom:764px;letter-spacing:0.25px;}
#t4_1{left:55px;bottom:752px;letter-spacing:0.25px;}
#haceC{left:133px;bottom:752px;letter-spacing:0.25px;}  
 
#t5_1{left:55px;bottom:741px;letter-spacing:0.26px;}
#DOND{left:141px;bottom:741px;letter-spacing:0.26px;}

#t6_1{left:55px;bottom:729px;letter-spacing:0.24px;}
#sihtrv{left:420px;bottom:726px;letter-spacing:0.24px;}
#nohtrv{left:448px;bottom:726px;letter-spacing:0.24px;}

#t7_1{left:55px;bottom:717px;letter-spacing:0.26px;}
#qprer{left:135px;bottom:717px;letter-spacing:0.26px;}  

#t8_1{left:35px;bottom:694px;letter-spacing:0.13px;}
#sinap{left:420px;bottom:692px;letter-spacing:0.13px;} 
#nonap{left:448px;bottom:692px;letter-spacing:0.13px;} 

#t9_1{left:55px;bottom:694px;letter-spacing:0.24px;}

#ta_1{left:55px;bottom:682px;letter-spacing:0.27px;}
#noapp{left:105px;bottom:682px;letter-spacing:0.27px;}

#tb_1{left:35px;bottom:659px;letter-spacing:0.13px;}
#sibiens{left:420px;bottom:660.1px;letter-spacing:0.13px;}  
#nobiens{left:448px;bottom:660.1px;letter-spacing:0.13px;}     


#tc_1{left:55px;bottom:659px;letter-spacing:0.23px;}
#td_1{left:55px;bottom:647px;letter-spacing:0.23px;} 
#sigripe2s{left:420px;bottom:649px;letter-spacing:0.23px;}  
#nogripe2s{left:448px;bottom:649px;letter-spacing:0.23px;}  

#te_1{left:55px;bottom:635px;letter-spacing:0.24px;}
#sitosest{left:420px;bottom:637px;letter-spacing:0.24px;}
#notosest{left:448px;bottom:637px;letter-spacing:0.24px;} 

#tf_1{left:35px;bottom:612px;letter-spacing:0.13px;}
#sihospi{left:420px;bottom:608px;letter-spacing:0.13px;} 
#nohospi{left:448px;bottom:608px;letter-spacing:0.13px;}    
 
#tg_1{left:55px;bottom:612px;letter-spacing:0.23px;}
#th_1{left:60px;bottom:600px;letter-spacing:0.22px;}

#ti_1{left:227px;bottom:600px;letter-spacing:0.26px;}
#ciruc{left:263px;bottom:600px;letter-spacing:0.26px;}

#tj_1{left:35px;bottom:577px;letter-spacing:0.13px;}
#sitranf{left:420px;bottom:573px;letter-spacing:0.13px;}  
#notranf{left:448px;bottom:573px;letter-spacing:0.13px;}  

#tk_1{left:55px;bottom:577px;letter-spacing:0.23px;}
#tl_1{left:60px;bottom:565px;letter-spacing:0.23px;}

#tm_1{left:55px;bottom:554px;letter-spacing:0.26px;}
#tranfc{left:130px;bottom:554px;letter-spacing:0.26px;}

#tn_1{left:35px;bottom:530px;letter-spacing:0.13px;}
#sitd{left:420px;bottom:525px;letter-spacing:0.13px;}
#notd{left:448px;bottom:525px;letter-spacing:0.13px;} 

#to_1{left:55px;bottom:530px;letter-spacing:0.22px;}

#tp_1{left:55px;bottom:518px;letter-spacing:0.26px;}
#tdc{left:95px;bottom:518px;letter-spacing:0.26px;}


#tq_1{left:35px;bottom:495px;letter-spacing:0.13px;}
#sipros{left:421px;bottom:490px;letter-spacing:0.13px;}    
#nopros{left:448px;bottom:490px;letter-spacing:0.13px;}  

#tr_1{left:55px;bottom:495px;letter-spacing:0.24px;}
#ts_1{left:60px;bottom:483px;letter-spacing:0.23px;}
#tt_1{left:60px;bottom:472px;letter-spacing:0.24px;}

#tu_1{left:55px;bottom:460px;letter-spacing:0.26px;}
#procu{left:95px;bottom:460px;letter-spacing:0.26px;}  

#tv_1{left:55px;bottom:448px;letter-spacing:0.22px;word-spacing:-0.2px;}
#sisf{left:420px;bottom:446px;letter-spacing:0.22px;word-spacing:-0.2px;}
#nosf{left:448px;bottom:446px;letter-spacing:0.22px;word-spacing:-0.2px;}
   
#tw_1{left:55px;bottom:437px;letter-spacing:0.24px;word-spacing:0.01px;}
#alerc{left:167px;bottom:437px;letter-spacing:0.24px;word-spacing:0.01px;}  

#tx_1{left:35px;bottom:413px;letter-spacing:0.13px;}
#sitom{left:420px;bottom:409px;letter-spacing:0.13px;}
#notom{left:448px;bottom:409px;letter-spacing:0.13px;} 

#ty_1{left:55px;bottom:413px;letter-spacing:0.24px;}

#tz_1{left:55px;bottom:401px;letter-spacing:0.26px;}
#tomdc{left:95px;bottom:401px;letter-spacing:0.26px;}


#t10_1{left:55px;bottom:390px;letter-spacing:0.25px;}
#parc{left:200px;bottom:390px;letter-spacing:0.25px;} 

#t11_1{left:55px;bottom:378px;letter-spacing:0.25px;}
#sihapde{left:420px;bottom:374px;letter-spacing:0.25px;}
#nohapde{left:448px;bottom:374px;letter-spacing:0.25px;}


#t12_1{left:60px;bottom:366px;letter-spacing:0.26px;}
#hapdec{left:167px;bottom:366px;letter-spacing:0.26px;}

#t13_1{left:35px;bottom:343px;letter-spacing:0.13px;}
#sisigsin{left:420px;bottom:339px;letter-spacing:0.13px;}
#nosigsin{left:448px;bottom:339px;letter-spacing:0.13px;}

#t14_1{left:55px;bottom:343px;letter-spacing:0.23px;}
#t15_1{left:60px;bottom:331px;letter-spacing:0.23px;}
#t16_1{left:60px;bottom:319px;letter-spacing:0.24px;}
#t17_1{left:60px;bottom:308px;letter-spacing:0.23px;}

#t18_1{left:60px;bottom:296px;letter-spacing:0.26px;}
#seisc{left:95px;bottom:296px;letter-spacing:0.26px;}


#t19_1{left:35px;bottom:273px;letter-spacing:0.16px;}
#sivacu{left:420px;bottom:269px;letter-spacing:0.16px;}
#novacu{left:448px;bottom:269px;letter-spacing:0.16px;}

#t1a_1{left:55px;bottom:273px;letter-spacing:0.24px;}

#t1b_1{left:55px;bottom:261px;letter-spacing:0.26px;}
#vcunc{left:167px;bottom:261px;letter-spacing:0.26px;}

#t1c_1{left:35px;bottom:237px;letter-spacing:-0.16px;}
#t1d_1{left:35px;bottom:223px;letter-spacing:-0.13px;word-spacing:-0.02px;}
#t1e_1{left:35px;bottom:209px;letter-spacing:-0.14px;word-spacing:-0.01px;}

#t1f_1{left:35px;bottom:183px;letter-spacing:-0.13px;}
#siparex{left:420px;bottom:180px;letter-spacing:-0.13px;}
#noparex{left:448px;bottom:180px;letter-spacing:-0.13px;} 


#t1g_1{left:54px;bottom:183px;letter-spacing:0.23px;}

#t1h_1{left:35px;bottom:159px;letter-spacing:0.16px;}
#sirxp{left:420px;bottom:156px;letter-spacing:0.16px;}
#norxp{left:448px;bottom:156px;letter-spacing:0.16px;}

#t1i_1{left:55px;bottom:159px;letter-spacing:0.23px;}
#t1j_1{left:60px;bottom:148px;letter-spacing:0.22px;}
#t1k_1{left:141px;bottom:148px;letter-spacing:0.22px;}
#t1l_1{left:60px;bottom:136px;letter-spacing:0.23px;}

#t1m_1{left:35px;bottom:113px;letter-spacing:0.16px;}
#sisustan{left:420px;bottom:110px;letter-spacing:0.16px;}
#nosustan{left:448px;bottom:110px;letter-spacing:0.16px;}

#t1n_1{left:55px;bottom:113px;letter-spacing:0.23px;}
#t1o_1{left:60px;bottom:101px;letter-spacing:0.25px;}

#t1p_1{left:35px;bottom:77px;letter-spacing:0.16px;}
#sirsd{left:420px;bottom:74px;letter-spacing:0.16px;}
#norsd{left:448px;bottom:74px;letter-spacing:0.16px;}

#t1q_1{left:55px;bottom:77px;letter-spacing:0.22px;word-spacing:0.02px;}
#t1r_1{left:60px;bottom:66px;letter-spacing:0.18px;word-spacing:0.03px;}
#t1s_1{left:207px;bottom:66px;letter-spacing:0.17px;}
#t1t_1{left:854px;bottom:784px;letter-spacing:0.19px;word-spacing:6.23px;}

#t1u_1{left:469px;bottom:775px;letter-spacing:0.16px;}
#sipriva{left:857px;bottom:772px;letter-spacing:0.16px;} 
#nopriva{left:885px;bottom:772px;letter-spacing:0.16px;} 


#t1v_1{left:490px;bottom:775px;letter-spacing:0.23px;}
#t1w_1{left:495px;bottom:764px;letter-spacing:0.21px;} 

#t1x_1{left:469px;bottom:740px;letter-spacing:0.16px;}
#simari{left:857px;bottom:737px;letter-spacing:0.16px;}
#nomari{left:885px;bottom:737px;letter-spacing:0.16px;}

#t1y_1{left:490px;bottom:740px;letter-spacing:0.23px;}

#t1z_1{left:498px;bottom:729px;letter-spacing:0.24px;}
#mric{left:785px;bottom:729px;letter-spacing:0.24px;}

#t20_1{left:469px;bottom:705px;letter-spacing:0.11px;}
#siheps{left:857px;bottom:703px;letter-spacing:0.11px;}
#noheps{left:885px;bottom:703px;letter-spacing:0.11px;}  

#t21_1{left:489px;bottom:705px;letter-spacing:0.14px;word-spacing:0.01px;}
#t22_1{left:498px;bottom:694px;letter-spacing:0.15px;word-spacing:0.01px;}

#t23_1{left:489px;bottom:682px;letter-spacing:0.18px;}
#enftsc{left:530px;bottom:682px;letter-spacing:0.18px;}

#t24_1{left:489px;bottom:671px;letter-spacing:0.16px;}
#sisecre{left:857px;bottom:668px;letter-spacing:0.16px;}
#nosecre{left:885px;bottom:668px;letter-spacing:0.16px;}


#t25_1{left:495px;bottom:659px;letter-spacing:0.17px;}

#t26_1{left:495px;bottom:648px;letter-spacing:0.18px;}
#secc{left:575px;bottom:648px;letter-spacing:0.18px;} 

#t27_1{left:495px;bottom:636px;letter-spacing:0.16px;}
#sitpv{left:570px;bottom:636px;letter-spacing:0.16px;}  

#t28_1{left:599px;bottom:636px;letter-spacing:0.17px;}
#notpv{left:617px;bottom:636px;letter-spacing:0.17px;} 

#t29_1{left:650px;bottom:636px;letter-spacing:0.16px;}
#curotpv{left:710px;bottom:636px;letter-spacing:0.16px;}

#t2a_1{left:743px;bottom:636px;letter-spacing:0.19px;}
#nocurotpv{left:763px;bottom:636px;letter-spacing:0.19px;}

#t2b_1{left:489px;bottom:625px;letter-spacing:0.15px;}
#silesp{left:857px;bottom:622px;letter-spacing:0.15px;}
#nolesp{left:885px;bottom:622px;letter-spacing:0.15px;}

#t2c_1{left:495px;bottom:613px;letter-spacing:0.17px;}

#t2d_1{left:495px;bottom:602px;letter-spacing:0.17px;}
#cuanlesg{left:560px;bottom:602px;letter-spacing:0.17px;}

#t2e_1{left:622px;bottom:602px;letter-spacing:0.16px;}
#lesgtra{left:698px;bottom:602px;letter-spacing:0.16px;}

#t2f_1{left:726px;bottom:602px;letter-spacing:0.19px;} 

#t2g_1{left:495px;bottom:590px;letter-spacing:0.18px;}
#securolesgsi{left:550px;bottom:590px;letter-spacing:0.18px;}
#securolesgno{left:620px;bottom:590px;letter-spacing:0.18px;}

#t2h_1{left:489px;bottom:579px;letter-spacing:0.16px;}
#sifilissi{left:857px;bottom:575px;letter-spacing:0.16px;}
#sifilisno{left:885px;bottom:575px;letter-spacing:0.16px;}

#t2i_1{left:495px;bottom:567px;letter-spacing:0.17px;}
#sific{left:565px;bottom:567px;letter-spacing:0.17px;}

#t2j_1{left:622px;bottom:567px;letter-spacing:0.16px;}
#sifit{left:695px;bottom:567px;letter-spacing:0.16px;}

#t2k_1{left:726px;bottom:567px;letter-spacing:0.19px;}
#sifitno{left:752px;bottom:567px;letter-spacing:0.19px;}

#t2l_1{left:495px;bottom:555px;letter-spacing:0.18px;}
#sifiliscurosi{left:565px;bottom:555px;letter-spacing:0.18px;} 
#sifiliscurono{left:625px;bottom:555px;letter-spacing:0.18px;}

#t2m_1{left:469px;bottom:537px;letter-spacing:0.11px;}
#acusi{left:857px;bottom:533px;letter-spacing:0.11px;}
#acuno{left:885px;bottom:533px;letter-spacing:0.11px;}

#t2n_1{left:489px;bottom:537px;letter-spacing:0.16px;}
#t2o_1{left:495px;bottom:525px;letter-spacing:0.16px;}

#t2p_1{left:495px;bottom:514px;letter-spacing:0.18px;}
#acupc{left:675px;bottom:514px;letter-spacing:0.18px;}

#t2q_1{left:469px;bottom:494px;letter-spacing:0.11px;}
#enfsi{left:857px;bottom:491px;letter-spacing:0.11px;}
#enfno{left:885px;bottom:491px;letter-spacing:0.11px;}

#t2r_1{left:489px;bottom:494px;letter-spacing:0.16px;}
#t2s_1{left:495px;bottom:482px;letter-spacing:0.16px;}
#t2t_1{left:495px;bottom:471px;letter-spacing:0.16px;}

#t2u_1{left:469px;bottom:451px;letter-spacing:0.11px;}
#rbsi{left:857px;bottom:451px;letter-spacing:0.11px;}
#rbno{left:885px;bottom:451px;letter-spacing:0.11px;} 

#t2v_1{left:489px;bottom:451px;letter-spacing:0.16px;}
#t2w_1{left:495px;bottom:439px;letter-spacing:0.16px;}
#t2x_1{left:498px;bottom:428px;letter-spacing:0.18px;}

#t2y_1{left:469px;bottom:409px;letter-spacing:0.11px;}
#zikasi{left:857px;bottom:406px;letter-spacing:0.11px;}
#zikano{left:885px;bottom:406px;letter-spacing:0.11px;}

#t2z_1{left:489px;bottom:409px;letter-spacing:0.16px;}
#t30_1{left:495px;bottom:397px;letter-spacing:0.16px;}
#t31_1{left:492px;bottom:386px;letter-spacing:0.18px;}
#zikac{left:528px;bottom:386px;letter-spacing:0.18px;}

#t32_1{left:469px;bottom:366px;letter-spacing:0.11px;}
#palusi{left:857px;bottom:359px;letter-spacing:0.11px;}
#paluno{left:885px;bottom:359px;letter-spacing:0.11px;}

#t33_1{left:489px;bottom:366px;letter-spacing:0.16px;}
#t34_1{left:495px;bottom:354px;letter-spacing:0.16px;}

#t35_1{left:469px;bottom:335px;letter-spacing:0.11px;}
#sicha{left:857px;bottom:332px;letter-spacing:0.11px;}
#nocha{left:884px;bottom:332px;letter-spacing:0.11px;}

#t36_1{left:489px;bottom:335px;letter-spacing:0.17px;}
#t37_1{left:495px;bottom:323px;letter-spacing:0.15px;word-spacing:0.01px;}

#t38_1{left:469px;bottom:307px;letter-spacing:0.11px;}
#sifuerapais{left:857px;bottom:303px;letter-spacing:0.11px;}
#nofuerapais{left:884px;bottom:303px;letter-spacing:0.11px;}

#t39_1{left:489px;bottom:307px;letter-spacing:0.16px;}

#t3a_1{left:489px;bottom:295px;letter-spacing:0.19px;}
#paisdonde{left:535px;bottom:295px;letter-spacing:0.19px;}
#paiscuanto{left:733px;bottom:295px;letter-spacing:0.19px;}

#t3b_1{left:489px;bottom:284px;letter-spacing:0.18px;word-spacing:0.01px;}
#cuantot{left:608px;bottom:284px;letter-spacing:0.18px;word-spacing:0.01px;}

#t3c_1{left:469px;bottom:266px;letter-spacing:0.11px;}
#sileyo{left:857px;bottom:261px;letter-spacing:0.11px;}
#noleyo{left:884px;bottom:261px;letter-spacing:0.11px;}

#t3d_1{left:489px;bottom:266px;letter-spacing:0.16px;}
#t3e_1{left:495px;bottom:254px;letter-spacing:0.17px;}
#t3f_1{left:469px;bottom:235px;letter-spacing:0.11px;}
#t3g_1{left:489px;bottom:235px;letter-spacing:0.16px;}

#t3h_1{left:489px;bottom:223px;letter-spacing:0.18px;}
#activi{left:489px;bottom:224px;letter-spacing:0.18px;}

#t3i_1{left:559px;bottom:192px;letter-spacing:0.15px;word-spacing:0.07px;}
#t3j_1{left:469px;bottom:172px;letter-spacing:0.11px;}
#t3k_1{left:489px;bottom:172px;letter-spacing:0.16px;}
#t3l_1{left:671px;bottom:172px;letter-spacing:0.19px;}
#t3m_1{left:469px;bottom:157px;letter-spacing:0.11px;}
#t3n_1{left:489px;bottom:157px;letter-spacing:0.17px;}
#t3o_1{left:495px;bottom:145px;letter-spacing:0.18px;}
#t3p_1{left:469px;bottom:130px;letter-spacing:0.11px;}
#t3q_1{left:489px;bottom:130px;letter-spacing:0.16px;}
#t3r_1{left:498px;bottom:118px;letter-spacing:0.17px;}
#t3s_1{left:478px;bottom:100px;letter-spacing:-0.32px;word-spacing:0.04px;}
#t3t_1{left:487px;bottom:88px;letter-spacing:0.16px;}
#t3u_1{left:487px;bottom:76px;letter-spacing:0.15px;}
#t3v_1{left:516px;bottom:62px;letter-spacing:0.17px;}
#t3w_1{left:784px;bottom:62px;letter-spacing:0.17px;}

#t3x_1{left:484px;bottom:59px;letter-spacing:0.16px;}
#aptos{left:520px;bottom:61px;letter-spacing:0.16px;}

#t3y_1{left:732px;bottom:59px;letter-spacing:0.17px;word-spacing:-0.45px;}
#noaptos{left:800px;bottom:62px;letter-spacing:0.17px;word-spacing:-0.45px;}

#t3z_1{left:33px;bottom:803px;letter-spacing:0.15px;}
#t40_1{left:737px;bottom:1273px;letter-spacing:-0.2px;}
#t41_1{left:34px;bottom:1141px;letter-spacing:0.12px;word-spacing:-0.01px;}

#t42_1{left:37px;bottom:1126px;letter-spacing:-0.12px;}
#leyosi{left:59px;bottom:1126px;letter-spacing:-0.12px;}

#t43_1{left:57px;bottom:1126px;letter-spacing:-0.2px;word-spacing:0.02px;}

#t44_1{left:37px;bottom:1113px;letter-spacing:-0.18px;word-spacing:-0.02px;}
#leyono{left:59px;bottom:1113px;letter-spacing:-0.18px;word-spacing:-0.02px;}

#t45_1{left:77px;bottom:1100px;letter-spacing:-0.2px;}
#t46_1{left:34px;bottom:1083px;letter-spacing:0.07px;word-spacing:0.06px;}

#t47_1{left:38px;bottom:1068px;letter-spacing:1.23px;word-spacing:0.09px;}
#nomap{left:240px;bottom:1070px;letter-spacing:1.23px;word-spacing:0.09px;}

#t48_1{left:38px;bottom:1049px;letter-spacing:0.07px;word-spacing:-0.01px;}
#lugfcn{left:260px;bottom:1052px;letter-spacing:0.07px;word-spacing:-0.01px;}

#t49_1{left:734px;bottom:1049px;letter-spacing:0.09px;}
#eda{left:798px;bottom:1052px;letter-spacing:0.09px; width: 10px;}

#t4a_1{left:38px;bottom:1013px;letter-spacing:0.1px;word-spacing:0.01px;}

#t4b_1{left:38px;bottom:977px;letter-spacing:0.1px;}
#gsan{left:179px;bottom:980px;letter-spacing:0.1px;}

#t4c_1{left:38px;bottom:941px;letter-spacing:0.11px;word-spacing:-0.21px;}
#nomase{left:205px;bottom:945px;letter-spacing:0.11px;word-spacing:-0.21px;}

#t4d_1{left:38px;bottom:923px;letter-spacing:0.09px;word-spacing:0.01px;}
#lugdon{left:350px;bottom:925px;letter-spacing:0.09px;word-spacing:0.01px;}

#t4e_1{left:38px;bottom:905px;letter-spacing:0.06px;word-spacing:0.04px;}
#dr{left:259px;bottom:907px;letter-spacing:0.06px;word-spacing:0.04px;}

#t4f_1{left:38px;bottom:887px;letter-spacing:0.09px;}
#munis{left:127px;bottom:889px;letter-spacing:0.09px;}

#t4g_1{left:38px;bottom:870px;letter-spacing:0.1px;word-spacing:-0.21px;}
#teladi{left:200px;bottom:872px;letter-spacing:0.1px;word-spacing:-0.21px;}

#t4h_1{left:38px;bottom:851px;letter-spacing:0.11px;}
#corre{left:210px;bottom:854px;letter-spacing:0.11px;}

#t4i_1{left:273px;bottom:887px;letter-spacing:-0.02px;word-spacing:0.12px;}
#dpd{left:400px;bottom:889px;letter-spacing:-0.02px;word-spacing:0.12px;}

#t4j_1{left:546px;bottom:887px;letter-spacing:0.06px;word-spacing:0.04px;}
#tprin{left:708px;bottom:889px;letter-spacing:0.06px;word-spacing:0.04px;}

#t4k_1{left:540px;bottom:905px;letter-spacing:0.1px;}
#brr{left:615px;bottom:907px;letter-spacing:0.1px;}

#t4l_1{left:273px;bottom:870px;letter-spacing:-0.03px;word-spacing:0.02px;}
#ocuemp{left:430px;bottom:872px;letter-spacing:-0.03px;word-spacing:0.02px;}

#t4m_1{left:570px;bottom:870px;letter-spacing:0.1px;word-spacing:0.01px;}
#tltra{left:745px;bottom:872px;letter-spacing:0.1px;word-spacing:0.01px;}

#t4n_1{left:482px;bottom:1049px;letter-spacing:0.09px;}
#diaa{left:510px;bottom:1052px;letter-spacing:0.09px;}

#t4o_1{left:564px;bottom:1049px;letter-spacing:0.11px;}
#mesaa{left:595px;bottom:1052px;letter-spacing:0.11px;}

#t4p_1{left:637px;bottom:1049px;letter-spacing:0.11px;}
#anios{left:665px;bottom:1052px;letter-spacing:0.11px;}

#t4q_1{left:858px;bottom:1049px;letter-spacing:0.1px;}
#t4r_1{left:227px;bottom:1013px;letter-spacing:0.09px;}
#t4s_1{left:377px;bottom:1013px;letter-spacing:0.03px;word-spacing:0.05px;}
#t4t_1{left:526px;bottom:1014px;letter-spacing:0.08px;}
#t4u_1{left:685px;bottom:1013px;letter-spacing:0.08px;}
#t4v_1{left:49px;bottom:995px;letter-spacing:0.09px;}

#t4w_1{left:343px;bottom:995px;letter-spacing:0.1px;}
#idennu{left:476px;bottom:997px;letter-spacing:0.1px;}

#t4x_1{left:623px;bottom:995px;letter-spacing:0.09px;}
#t4y_1{left:262px;bottom:977px;letter-spacing:0.09px;}
#t4z_1{left:267px;bottom:959px;letter-spacing:0.11px;}
#t50_1{left:352px;bottom:959px;letter-spacing:0.1px;}
#t51_1{left:441px;bottom:959px;letter-spacing:0.11px;}
#t52_1{left:540px;bottom:959px;letter-spacing:0.11px;}
#t53_1{left:708px;bottom:959px;letter-spacing:0.1px;}
#t54_1{left:309px;bottom:1033px;letter-spacing:0.03px;word-spacing:0.06px;}
#t55_1{left:502px;bottom:1033px;letter-spacing:-0.07px;}
#t56_1{left:603px;bottom:1032px;letter-spacing:0.09px;}
#t57_1{left:701px;bottom:1033px;letter-spacing:0.09px;}
#t58_1{left:412px;bottom:1034px;letter-spacing:0.08px;}
#t59_1{left:807px;bottom:1033px;letter-spacing:0.06px;}

#t5a_1{left:237px;bottom:1278px;letter-spacing:-0.07px;}
#diaA{left:237px;bottom:1267px;letter-spacing:-0.07px;} 

#t5b_1{left:285px;bottom:1278px;letter-spacing:-0.08px;}
#mesA{left:287px;bottom:1267px;letter-spacing:-0.08px;}

#t5c_1{left:330px;bottom:1278px;letter-spacing:-0.08px;}
#anioA{left:330px;bottom:1267px;letter-spacing:-0.08px;}

#t5d_1{left:366px;bottom:1270px;letter-spacing:-0.2px;}
#t5e_1{left:461px;bottom:1264px;letter-spacing:-0.07px;}
#t5f_1{left:607px;bottom:1287px;letter-spacing:-0.16px;word-spacing:0.02px;}
#t5g_1{left:617px;bottom:1271px;letter-spacing:-0.14px;}
#t5h_1{left:621px;bottom:1326px;letter-spacing:-0.21px;}
#t5i_1{left:637px;bottom:1308px;letter-spacing:-0.13px;}
#t5j_1{left:616px;bottom:1355px;}
#t5k_1{left:262px;bottom:1325px;letter-spacing:-0.16px;}
#t5l_1{left:374px;bottom:1325px;letter-spacing:-0.23px;word-spacing:0.09px;}
#t5m_1{left:455px;bottom:1325px;letter-spacing:-0.17px;}
#t5n_1{left:332px;bottom:1296px;letter-spacing:-0.3px;}
#t5o_1{left:361px;bottom:1294px;letter-spacing:-0.26px;}
#t5p_1{left:267px;bottom:1313px;letter-spacing:0.11px;}
#t5q_1{left:252px;bottom:1354px;}
#t5r_1{left:37px;bottom:1032px;letter-spacing:0.1px;}
#t5s_1{left:207px;bottom:1033px;letter-spacing:0.14px;}
#t5t_1{left:118px;bottom:1032px;letter-spacing:0.1px;}
#t5u_1{left:550px;bottom:943px;letter-spacing:0.09px;word-spacing:-0.21px;}

#t5v_1{left:484px;bottom:851px;letter-spacing:0.06px;word-spacing:0.04px;}
#telcon{left:700px;bottom:854px;letter-spacing:0.06px;word-spacing:0.04px;}

#t5w_1{left:43px;bottom:1247px;letter-spacing:-0.16px;word-spacing:1.83px;}
#t5x_1{left:43px;bottom:1236px;letter-spacing:-0.15px;word-spacing:0.47px;}
#t5y_1{left:43px;bottom:1224px;letter-spacing:-0.16px;word-spacing:3.12px;}
#t5z_1{left:43px;bottom:1213px;letter-spacing:-0.15px;word-spacing:1.07px;}
#t60_1{left:43px;bottom:1201px;letter-spacing:-0.15px;}
#t61_1{left:43px;bottom:1190px;letter-spacing:0.19px;word-spacing:4.08px;}
#t62_1{left:43px;bottom:1178px;letter-spacing:0.51px;word-spacing:3.75px;}
#t63_1{left:43px;bottom:1167px;letter-spacing:-0.01px;word-spacing:4.29px;}
#t64_1{left:38px;bottom:834px;letter-spacing:0.09px;word-spacing:0.01px;}
#t65_1{left:797px;bottom:1013px;letter-spacing:0.09px;}
#t66_1{left:38px;bottom:959px;letter-spacing:0.07px;word-spacing:-0.15px;}

.s0_1{font-size:14px;font-family:Arial-BoldMT_g;color:#231F20;}

.s1_v1{font-size:10px;font-family:Arial, Helvetica, sans-serif;color:#231F20; text-transform: uppercase;}
.tam{
	font-size:9px;font-family:Arial, Helvetica, sans-serif;color:#231F20; text-transform: uppercase;	
}

.s1_1{font-size:10px;font-family:Arial-BoldMT_g;color:#231F20;}
.s2_1{font-size:10px;font-family:Arial-Black_n;color:#231F20;}

.s3_1{font-size:12px;font-family:Arial-BoldMT_g;color:#FFF;}  

.s4_1{font-size:16px;font-family:Arial-BoldMT_g;color:#231F20;}
.s5_1{font-size:12px;font-family:Arial-BoldMT_g;color:#231F20;}
.s6_1{font-size:11px;font-family:Arial-BoldMT_g;color:#231F20;}
.s7_1{font-size:18px;font-family:Arial-BoldMT_g;color:#231F20;}
.s8_1{font-size:13px;font-family:Arial-BoldMT_g;color:#231F20;}
.s9_1{font-size:19px;font-family:Arial-BoldMT_g;color:#231F20;}
.t.v0_1{transform:scaleX(1.027);}
.t.v1_1{transform:scaleX(0.958);}
.t.v2_1{transform:scaleX(1.006);}
.t.v3_1{transform:scaleX(1.24);}
.t.v4_1{transform:scaleX(0.911);}
.t.v5_1{transform:scaleX(1.126);}
.t.v6_1{transform:scaleX(1.076);}
.t.v7_1{transform:scaleX(1.029);}
.t.v8_1{transform:scaleX(1.067);}
.t.v9_1{transform:scaleX(1.043);}
.t.v10_1{transform:scaleX(1.036);}
.t.v11_1{transform:scaleX(1.015);}
.t.v12_1{transform:scaleX(0.992);}
.t.v13_1{transform:scaleX(1.005);}
.t.v14_1{transform:scaleX(1.013);}
.t.v15_1{transform:scaleX(1.048);}
.t.v16_1{transform:scaleX(1.023);}
.t.v17_1{transform:scaleX(1.089);}
.t.v18_1{transform:scaleX(1.068);}
.t.v19_1{transform:scaleX(0.985);}
.t.v20_1{transform:scaleX(1.042);}
.t.v21_1{transform:scaleX(1.009);}
.t.v22_1{transform:scaleX(0.927);}
.t.v23_1{transform:scaleX(1.01);}
.t.v24_1{transform:scaleX(0.916);}
.t.v25_1{transform:scaleX(0.886);}
.t.v26_1{transform:scaleX(0.934);}
.t.v27_1{transform:scaleX(1.016);}
.t.v28_1{transform:scaleX(0.965);}
.t.v29_1{transform:scaleX(1.011);}
.t.v30_1{transform:scaleX(1.053);}
</style>
<!-- End inline CSS -->

<!-- Begin embedded font definitions -->
<style id="fonts1" type="text/css" >

@font-face {
	font-family: Arial-Black_n;
	src: url("../../../../assets/fonts/Arial-Black_n.woff") format("woff");
}

@font-face {
	font-family: Arial-BoldMT_g;
	src: url("../../../../assets/fonts/Arial-Black_n.woff") format("woff");
}





</style>
<!-- End embedded font definitions -->

<!-- Begin page background -->
<div id="pg1Overlay" style="width:100%; height:100%; position:absolute; z-index:1; background-color:rgba(0,0,0,0); -webkit-user-select: none;"></div>
<div id="pg1" style="-webkit-user-select: none;"><object width="935" height="1429" data="assets/1.svg" type="image/svg+xml" id="pdf1" style="width:935px; height:1429px; -moz-transform:scale(1); z-index: 0;"></object></div>
<!-- End page background -->


<!-- Begin text definitions (Positioned/styled in CSS) -->
<div class="text-container"><span id="t1_1" class="t v0_1 s0_1">SI NO </span>

<span id="t2_1" class="t v1_1 s1_1">1. </span><span id="t3_1" class="t v1_1 s1_1">¿Ha donado sangre anteriormente? </span>
<span id="sidons" class="t v1_1 s1_1" *ngIf="data.ha_donando == 1">X</span>  
<span id="nodons" class="t v1_1 s1_1" *ngIf="data.ha_donando == 0">X</span> 

<span id="t4_1" class="t v1_1 s1_1">¿Hace cuánto?____________________(escribir fecha) </span>
<span id="haceC" class="t v1_1 s1_v1">{{data?.fec_ul_don}}</span>

<span id="t5_1" class="t v1_1 s1_1">¿En donde donó?__________________ </span>
<span id="DOND" class="t v1_1 tam">{{data?.lug_ul_don}}</span>


<span id="t6_1" class="t v1_1 s1_1">¿Ha tenido reacción adversa a la donación?</span>
<span id="sihtrv" class="t v1_1 s1_1" *ngIf="data.pres_reac" >X</span>
<span id="nohtrv" class="t v1_1 s1_1" *ngIf="data.pres_reac == null" >X</span>

<span id="t7_1" class="t v1_1 s1_1">¿Que presento?__{{data?.pres_reac}}_______________________________________________ </span>
<span id="qprer" class="t v1_1 tam">{{data?.pres_reac}}</span>


<span id="t8_1" class="t v1_1 s1_1">2. </span><span id="t9_1" class="t v1_1 s1_1">¿Has sido declarado alguna vez no apto para donar sangre? </span>
<span id="sinap" class="t v1_1 s1_1" *ngIf="data?.no_apto == 1">X</span>
<span id="nonap" class="t v1_1 s1_1" *ngIf="data?.no_apto == 0">X</span> 

<span id="ta_1" class="t v1_1 s1_1">¿Porque?_{{data?.no_apto_p}}______________________________________________________ </span>
<span id="noapp" class="t v1_1 tam">{{data?.no_apto_p}}</span>

<span id="tb_1" class="t v1_1 s1_1">3. </span><span id="tc_1" class="t v1_1 s1_1">¿ Se ha sentido bien de salud las ultimas dos(2) semanas? </span>
<span id="sibiens" class="t v1_1 s1_1" *ngIf="data.b_2_sem == 1">X</span>
<span id="nobiens" class="t v1_1 s1_1" *ngIf="data.b_2_sem == 0">X</span>

<span id="td_1" class="t v1_1 s1_1">¿ Ha tenido gripa en las ultimas dos(2) semanas?</span>
<span id="sigripe2s" *ngIf="data.g_2_sem == 1" class="t v1_1 s1_1" >X</span>
<span id="nogripe2s" *ngIf="data.g_2_sem == 0" class="t v1_1 s1_1">X</span>

<span id="te_1" class="t v1_1 s1_1">¿ Esta tosiendo o estornudando?   </span> 
<span id="sitosest" *ngIf="data?.tos_est == 1" class="t v1_1 s1_1">X</span> 
<span id="notosest" *ngIf="data?.tos_est == 0" class="t v1_1 s1_1">X</span> 


<span id="tf_1" class="t v1_1 s1_1">4. </span><span id="tg_1" class="t v1_1 s1_1">¿En los últimos 12 meses. ¿estuvo hospitalizado bajo tratamiento médico</span>
<span id="sihospi" class="t v1_1 s1_1" *ngIf="data?.h_12_mes == 1" >X</span>
<span id="nohospi" class="t v1_1 s1_1" *ngIf="data?.h_12_mes == 0" >X</span>

<span id="th_1" class="t v1_1 s1_1">o le han realizado alguna cirugía? </span><span id="ti_1" class="t v1_1 s1_1">¿Cual?________________________ </span>
<span id="ciruc" class="t v1_1 tam">{{data?.h_12_mes_c}}</span>

<span id="tj_1" class="t v1_1 s1_1">5. </span><span id="tk_1" class="t v1_1 s1_1">¿Alguna vez usted o su pareja ha recibido transfusión sanguínea</span>
<span id="tl_1" class="t v1_1 s1_1">trasplante de Órganos, tejidos u hormonas del crecimiento? </span>
<span id="sitranf" *ngIf="data?.tr_fus_pl == 1" class="t v1_1 s1_1">X</span>
<span id="notranf" *ngIf="data?.tr_fus_pl == 0" class="t v1_1 s1_1">X</span>

<span id="tm_1" class="t v1_1 s1_1">¿Hace Cuánto?__________________________________________________ </span>
<span id="tranfc" class="t v1_1 tam">{{data?.tr_fus_pl_c}}</span>

<span id="tn_1" class="t v1_1 s1_1">6. </span><span id="to_1" class="t v1_1 s1_1">¿En los últimos siete(7) días le han realizado tratamientos dentales?</span>
<span id="sitd" *ngIf="data?.tr_de_7_d ==  1" class="t v1_1 s1_1">X</span> 
<span id="notd" *ngIf="data?.tr_de_7_d ==  0" class="t v1_1 s1_1">X</span>

<span id="tp_1" class="t v1_1 s1_1">¿Cual?_________________________________________________________ </span>
<span id="tdc" class="t v1_1 tam">{{data?.tr_de_7_d_c}}</span>

<span id="tq_1" class="t v1_1 s1_1">7. </span><span id="tr_1" class="t v1_1 s1_1">¿Ha presentado alguno de los siguientes problemas de salud? Enfermedad</span>
<span id="ts_1" class="t v1_1 s1_1">de la sangre, Pulmón, corazón, cáncer trastornos mentales, Diabetes, </span>
<span id="tt_1" class="t v1_1 s1_1">Hipertensión, mareos, desmayos, Hipotensión, convulsiones.  </span>
<span id="sipros" *ngIf="data?.pres_pr  ==  1" class="t v1_1 s1_1">X</span>
<span id="nopros" *ngIf="data?.pres_pr  ==  0" class="t v1_1 s1_1">X</span>

<span id="tu_1" class="t v1_1 s1_1">¿Cual?_________________________________________________________ </span> 
<span id="procu" class="t v1_1 tam">{{data?.pres_pr_c}}</span>  

<span id="tv_1" class="t v1_1 s1_1">¿Sufre de Alergias?</span>
<span id="sisf" *ngIf="data?.alerg == 1" class="t v1_1 s1_1">X</span>
<span id="nosf" *ngIf="data?.alerg == 0" class="t v1_1 s1_1">X</span>

<span id="tw_1" class="t v1_1 s1_1">¿A que es alérgico (a)?_________________________ </span>
<span id="alerc" class="t v1_1 tam">{{data?.alerg_c}}</span>

<span id="tx_1" class="t v1_1 s1_1">8. </span><span id="ty_1" class="t v1_1 s1_1">¿En el último mes, ha tomado algún medicamento?</span>
<span id="sitom" *ngIf="data?.ha_tom_medic == 1" class="t v1_1 s1_1">X</span>
<span id="notom" *ngIf="data?.ha_tom_medic == 0" class="t v1_1 s1_1">X</span>

<span id="tz_1" class="t v1_1 s1_1">¿Cual?________________________________________________________ </span> 
<span id="tomdc" class="t v1_1 tam">{{data?.medics}}</span> 

<span id="t10_1" class="t v1_1 s1_1">¿Para que le fue formulado?_______________________________________ </span>
<span id="parc" class="t v1_1 tam">{{data?.txtMediPara}}</span>

<span id="t11_1" class="t v1_1 s1_1">¿Ha padecido o padece de alguna enfermedad no mencionada</span>
<span id="sihapde" *ngIf="data?.txtPadeceEnfUl == 1" class="t v1_1 s1_1">X</span>
<span id="nohapde" *ngIf="data?.txtPadeceEnfUl ==  0" class="t v1_1 s1_1">X</span>

<span id="t12_1" class="t v1_1 s1_1">anteriormente?.Cual?____________________________________________ </span>
<span id="hapdec" class="t v1_1 tam">{{data?.txtPadeceEnfUlCaul}}</span>

<span id="t13_1" class="t v1_1 s1_1">9. </span><span id="t14_1" class="t v1_1 s1_1">¿En los últimos seis (6) meses ha presentado alguno de los siguientes </span>
<span id="t15_1" class="t v1_1 s1_1">síntomas? Pérdida inexplicable de peso, tos persistente (por mas de dos </span>
<span id="t16_1" class="t v1_1 s1_1">semanas), inﬂamación permanentes de los ganglios, manchas o lesiones </span> 
<span id="t17_1" class="t v1_1 s1_1" data-mappings='[[22,"fi"]]'>en la piel o mucosas, ﬁebre persistente, diarrea, sudoración nocturna </span> 

<span id="sisigsin" *ngIf="data?.sin_6_mes == 1" class="t v1_1 s1_1">X</span>
<span id="nosigsin" *ngIf="data?.sin_6_mes == 0" class="t v1_1 s1_1">X</span>

<span id="t18_1" class="t v1_1 s1_1">¿Cual?_____________________ </span>
<span id="seisc" class="t v1_1 tam">{{data?.sin_6_mes_c}}</span>


<span id="t19_1" class="t v1_1 s1_1">10. </span><span id="t1a_1" class="t v1_1 s1_1">¿Ha sido vacunado el ultimo año?</span>
<span id="sivacu"  *ngIf="data?.vacun == 1"  class="t v1_1 s1_1">X</span>
<span id="novacu" *ngIf="data?.vacun == 0" class="t v1_1 s1_1">X</span>

<span id="t1b_1" class="t v1_1 s1_1">¿Que vacuna recibió?____________________________________________ </span>
<span id="vcunc" class="t v1_1 tam">{{data?.vacun_c}}</span>

<span id="t1c_1" class="t v1_1 s2_1">APRECIADO DONANTE: Con las preguntas que vienen a continuación buscamos </span>
<span id="t1d_1" class="t v1_1 s2_1">asegurar que los pacientes que van a recibir su sangre no van a correr riesgos </span>
<span id="t1e_1" class="t v1_1 s2_1">de contraer una enfermedad infecciosa a través de la transfusión. </span>

<span id="t1f_1" class="t v1_1 s1_1">11. </span><span id="t1g_1" class="t v1_1 s1_1">¿Ha tenido una nueva pareja sexual en los últimos seis (6) meses?</span>
<span id="siparex" *ngIf="data?.par_s_6_mes == 1" class="t v1_1 s1_1">X</span>
<span id="noparex" *ngIf="data?.par_s_6_mes == 0"  class="t v1_1 s1_1">X</span>

<span id="t1h_1" class="t v1_1 s1_1">12. </span><span id="t1i_1" class="t v1_1 s1_1">¿Ha tenido relaciones sexuales con personas pertenecientes a alguna de</span>
<span id="t1j_1" class="t v1_1 s1_1">las poblaciones </span><span id="t1k_1" class="t v1_1 s1_1">(trabajadores sexuales, habitantes de la calle, personas </span>
<span id="t1l_1" class="t v1_1 s1_1">que se inyectan drogas). </span>
<span id="sirxp" *ngIf="data?.rel_s_pob == 1"  class="t v1_1 s1_1">X</span>
<span id="norxp" *ngIf="data?.rel_s_pob == 0" class="t v1_1 s1_1">X</span>

<span id="t1m_1" class="t v1_1 s1_1">13. </span><span id="t1n_1" class="t v1_1 s1_1">¿Has recibido sustancias psicoactivas o dinero a cambio de relaciones</span>
<span id="t1o_1" class="t v1_1 s1_1">sexuales? </span>
<span id="sisustan" *ngIf="data?.rec_su  == 1" class="t v1_1 s1_1">X</span>
<span id="nosustan" *ngIf="data?.rec_su  == 0" class="t v1_1 s1_1">X</span>

<span id="t1p_1" class="t v1_1 s1_1">14. </span><span id="t1q_1" class="t v1_1 s1_1">¿Ha tenido relaciones sexuales con personas diagnosticadas con HIV, </span>
<span id="t1r_1" class="t v1_1 s1_1">Hepatitis B, Hepatitis C, HTLV </span><span id="t1s_1" class="t v1_1 s1_1" data-mappings='[[10,"fi"]]'>I y II, Síﬁlis. </span>
<span id="sirsd"  *ngIf="data?.rel_p_d == 1" class="t v1_1 s1_1">X</span>
<span id="norsd"  *ngIf="data?.rel_p_d == 0" class="t v1_1 s1_1">X</span>

<span id="t1t_1" class="t v0_1 s0_1">SI NO </span> 

<span id="t1u_1" class="t v2_1 s1_1">15. </span><span id="t1v_1" class="t v2_1 s1_1">¿En los últimos 12 meses usted o su pareja sexual han estado privados</span>
<span id="t1w_1" class="t v2_1 s1_1">de la libertad? </span>
<span id="sipriva" *ngIf="data?.ul_12_m_p == 1"  class="t v2_1 s1_1">X</span>
<span id="nopriva" *ngIf="data?.ul_12_m_p == 0" class="t v2_1 s1_1">X</span>


<span id="t1x_1" class="t v2_1 s1_1">16. </span><span id="t1y_1" class="t v2_1 s1_1">¿Uso o usa marihuana, cocaína, heroína, bazuco, estimulante,</span>
<span id="simari"  *ngIf="data?.usa_m_a == 1" class="t v2_1 s1_1">X</span>
<span id="nomari"   *ngIf="data?.usa_m_a == 0" class="t v2_1 s1_1">X</span>

<span id="t1z_1" class="t v2_1 s1_1">alucinógeno o alguna otra sustancia psicoactiva? Cual? ____________ </span>
<span id="mric" class="t v2_1 tam"></span>

<span id="t20_1" class="t v2_1 s1_1">17. </span><span id="t21_1" class="t v2_1 s1_1" data-mappings='[[31,"fi"]]'>¿Tuvo o ha sido tratado para síﬁlis, gonorrea, herpes genital, condiloma, </span> 
<span id="t22_1" class="t v2_1 s1_1">hepatitis, SIDA o cualquier otra enfermedad de transmisión sexual? </span>
<span id="siheps" *ngIf="data?.enf_tr_s == 1" class="t v2_1 s1_1">X</span>
<span id="noheps" *ngIf="data?.enf_tr_s == 0" class="t v2_1 s1_1">X</span>

<span id="t23_1" class="t v2_1 s1_1">¿Cual?______________________________________________________ </span>
<span id="enftsc" class="t v2_1 tam">{{data?.enf_tr_s_c}}</span>

<span id="t24_1" class="t v2_1 s1_1">¿Usted recuerda si alguna vez en su vida a botado por el pene o vagina</span>
<span id="t25_1" class="t v2_1 s1_1">alguna secreción blanca o verde? </span>
<span id="sisecre" *ngIf="data?.bo_sec == 1" class="t v2_1 s1_1">X</span>
<span id="nosecre" *ngIf="data?.bo_sec == 0" class="t v2_1 s1_1">X</span>

<span id="t26_1" class="t v2_1 s1_1">Hace Cuanto?_______________________________________________ </span>
<span id="secc" class="t v2_1 tam">{{data?.bo_sec_ct}}</span>

<span id="t27_1" class="t v2_1 s1_1">Fue tratado Si_____ </span><span id="t28_1" class="t v2_1 s1_1">No_____ </span><span id="t29_1" class="t v2_1 s1_1">Se Curó Si______ </span><span id="t2a_1" class="t v2_1 s1_1">No_______ </span>
<span id="sitpv" class="t v2_1 s1_1">{{data?.bo_sec_t == 1 && data?.bo_sec == 1 ? "X" : ""}}</span>
<span id="notpv" class="t v2_1 s1_1">{{data?.bo_sec_t == 0 && data?.bo_sec == 1 ? "X" : ""}}</span>
<span id="curotpv" class="t v2_1 s1_1">{{data?.bo_sec_cu  == 1 && data?.bo_sec == 1 ? "X" : "" }}</span>
<span id="nocurotpv" class="t v2_1 s1_1">{{data?.bo_sec_cu  == 0 && data?.bo_sec == 1 ? "X" : "" }}</span>

<span id="t2b_1" class="t v2_1 s1_1">¿Usted recuerda si ha tenido alguna lesión, grano, llaga, verruga en el  </span> 
<span id="t2c_1" class="t v2_1 s1_1">pene o vagina? </span>
<span id="silesp" *ngIf="data?.les_gen == 1" class="t v2_1 s1_1">X</span> 
<span id="nolesp" *ngIf="data?.les_gen == 0"  class="t v2_1 s1_1">X</span> 

<span id="t2d_1" class="t v2_1 s1_1">Hace Cuanto__________ </span><span id="t2e_1" class="t v2_1 s1_1">Fue tratado Si_____ </span><span id="t2f_1" class="t v2_1 s1_1">No________ </span>
<span id="cuanlesg" class="t v2_1 tam">{{data?.les_gen_ct}}2</span>
<span id="lesgtra" class="t v2_1 s1_1">{{data?.les_gen_t == 1 && data?.les_gen == 1 ? "X" : "" }}</span>
<span id="lesgtrano" class="t v2_1 s1_1">{{data?.les_gen_t == 0 && data?.les_gen == 1 ? "X" : "" }}</span>

<span id="t2g_1" class="t v2_1 s1_1">Se Curó Si________No_________ </span>
<span id="securolesgsi" class="t v2_1 s1_1">_{{data?.les_gen_cr == 1 && data?.les_gen == 1 ? "X" : ""}}</span>
<span id="securolesgno" class="t v2_1 s1_1">{{data?.les_gen_cr == 0 && data?.les_gen == 1 ? "X" : ""}}</span>

<span id="t2h_1" class="t v2_1 s1_1" data-mappings='[[53,"fi"]]'>¿Usted recuerda si alguna vez en su vida ha tenido síﬁlis?</span>
<span id="sifilissi" class="t v2_1 s1_1" *ngIf="data?.sifilis == 1" data-mappings='[[53,"fi"]]'>X</span>
<span id="sifilisno" class="t v2_1 s1_1" *ngIf="data?.sifilis == 0" data-mappings='[[53,"fi"]]'>X</span>

<span id="t2i_1" class="t v2_1 s1_1">Hace Cuanto__________ </span><span id="t2j_1" class="t v2_1 s1_1">Fue tratado Si_____ </span><span id="t2k_1" class="t v2_1 s1_1">No_________ </span>
<span id="sific" class="t v2_1 tam">{{data?.sifilis_ct}}</span>
<span id="sifit" class="t v2_1 s1_1">{{data?.sifilis == 1 && data?.sifilis_t == 1 ? "X" : ""}}</span>
<span id="sifitno" class="t v2_1 s1_1">{{data?.sifilis == 1 && data?.sifilis_t == 0 ? "X" : ""}}</span>

<span id="t2l_1" class="t v2_1 s1_1">Se Curó Si________No_________ </span>
<span id="sifiliscurosi" class="t v2_1 s1_1" >{{data?.sifilis == 1 && data?.sifilis_cr == 1 ? "X" : ""}}</span>
<span id="sifiliscurono" class="t v2_1 s1_1" >{{data?.sifilis == 1 && data?.sifilis_cr == 0 ? "X" : ""}}</span>

<span id="t2m_1" class="t v2_1 s1_1">18. </span><span id="t2n_1" class="t v2_1 s1_1">¿En los últimos 6 meses le han practicado acupuntura, tatuajes, </span>
<span id="t2o_1" class="t v2_1 s1_1">perforaciones de oreja, piercing, maquillaje permanente u otros </span>
<span id="acusi" *ngIf="data?.acup == 1" class="t v2_1 s1_1">X</span>
<span id="acuno" *ngIf="data?.acup == 0" class="t v2_1 s1_1">X</span>

<span id="t2p_1" class="t v2_1 s1_1">procedimientos similares? ¿Cual?_____________________________ </span> 
<span id="acupc" class="t v2_1 tam">{{data?.acup_c}}</span> 

<span id="t2q_1" class="t v2_1 s1_1">19. </span><span id="t2r_1" class="t v2_1 s1_1">¿En el ultimo mes ha padecido alguna enfermedad contagiosa o ha </span> 
<span id="t2s_1"  class="t v2_1 s1_1">estado en contacto con personas que padecen sarampión, rubéola, </span>
<span id="t2t_1" class="t v2_1 s1_1">paperas o varicela? </span>

<span id="enfsi" *ngIf="data?.enf_con == 1" class="t v2_1 s1_1">X</span>
<span id="enfno" *ngIf="data?.enf_con == 0" class="t v2_1 s1_1">X</span>

<span id="t2u_1" class="t v2_1 s1_1">20. </span><span id="t2v_1" class="t v2_1 s1_1">¿En los últimos 6 meses ha tenido accidentes de riesgo biológico  </span>
<span id="t2w_1" class="t v2_1 s1_1">(contacto con sangre, líquidos corporales, pinchazos con agujas </span>
<span id="t2x_1" class="t v2_1 s1_1">contaminadas)? </span>

<span id="rbsi" *ngIf="data?.rie_bio == 1" class="t v2_1 s1_1">X</span>
<span id="rbno" *ngIf="data?.rie_bio == 0" class="t v2_1 s1_1">X</span>

<span id="t2y_1" class="t v2_1 s1_1">21. </span><span id="t2z_1" class="t v2_1 s1_1">¿Alguna vez en su vida se ha enfermado o ha recibido tratamiento para  </span>
<span id="t30_1" class="t v2_1 s1_1" data-mappings='[[26,"fi"]]'>paludismo, lehismaniasis, ﬁebre amarilla, dengue, zika, chikunguña </span>
<span id="zikasi" *ngIf="data?.tr_pal == 1" class="t v2_1 s1_1">X</span>
<span id="zikano" *ngIf="data?.tr_pal == 0" class="t v2_1 s1_1">X</span>

<span id="t31_1" class="t v2_1 s1_1">¿Cual?______________________________________________________ </span> 
<span id="zikac" class="t v2_1 tam">{{data?.tr_pal_c}}</span>

<span id="t32_1" class="t v2_1 s1_1">22. </span><span id="t33_1" class="t v2_1 s1_1">¿En lo (2) últimos años ha visitado zonas donde hay paludismo,  </span>
<span id="t34_1" class="t v2_1 s1_1" data-mappings='[[15,"fi"]]'>lehismaniasis, ﬁebre amarilla o dengue? </span>
<span id="palusi" *ngIf="data?.vis_2a_pal ==  1" class="t v2_1 s1_1">X</span>
<span id="paluno" *ngIf="data?.vis_2a_pal ==  0" class="t v2_1 s1_1">X</span>

<span id="t35_1" class="t v2_1 s1_1">23. </span><span id="t36_1" class="t v2_1 s1_1">¿Ha tenido enfermedad de Chagas o ha estado en zonas donde habita </span>
<span id="t37_1" class="t v2_1 s1_1">el insecto pito, chinches picudo, besador, rondador o chupa sangre? </span>
<span id="sicha" *ngIf="data?.enf_ch == 1" class="t v2_1 s1_1">X</span>
<span id="nocha" *ngIf="data?.enf_ch == 0" class="t v2_1 s1_1">X</span>


<span id="t38_1" class="t v2_1 s1_1">24. </span><span id="t39_1" class="t v2_1 s1_1">¿Has vivido fuera del país o de la ciudad en que reside actualmente?   </span>
<span id="sifuerapais" *ngIf="data?.viv_fp ==  1"  class="t v2_1 s1_1">X</span>
<span id="nofuerapais" *ngIf="data?.viv_fp ==  0" class="t v2_1 s1_1">X</span>

<span id="t3a_1" class="t v2_1 s1_1">¿Donde?____________________¿Hace Cuanto?____________________ </span>
<span id="paisdonde" class="t v2_1 tam" >{{data?.viv_fp_d}}</span>
<span id="paiscuanto" class="t v2_1 tam">{{data?.viv_fp_hc}}</span>

<span id="t3b_1" class="t v2_1 s1_1">¿Por Cuanto Tiempo?_____________________ </span>
<span id="cuantot" class="t v2_1 tam">{{data?.viv_fp_ct}}</span>

<span id="t3c_1" class="t v2_1 s1_1">25. </span><span id="t3d_1" class="teeee v2_1 s1_1" >¿Leyó y comprendió Usted el cuestionario y fueron contestadas todas  </span> 
<span id="t3e_1" class="t v2_1 s1_1">sus dudas al respecto? </span> 
<span id="sileyo" *ngIf="data?.leyo_c ==  1" class="t v2_1 s1_1">X</span>
<span id="noleyo" *ngIf="data?.leyo_c ==  0" class="t v2_1 s1_1">X</span>


<span id="t3f_1" class="t v2_1 s1_1">26. </span><span id="t3g_1" class="t v2_1 s1_1">¿Que actividad realizará después de la donación? </span>    
<span id="t3h_1" class="t v2_1 s1_1">_____________________________________________________________ </span>
<span id="activi" class="t v2_1 tam">{{data?.act_real}}</span>

<span id="t3i_1" class="t v2_1 s2_1">SI USTED ES MUJER POR FAVOR DILIGENCIE </span>
<span id="t3j_1" class="t v2_1 s1_1">27. </span><span id="t3k_1" class="t v2_1 s1_1">Fecha de su ultima menstruación. </span><span id="t3l_1" class="t v2_1 s1_1">Dia_{{mestru ? mestru[2] : "" }}_______Mes__{{mestru ? mestru[1] : "" }}______Año__{{mestru ? mestru[0] : "" }}______ </span>
<span id="t3m_1" class="t v2_1 s1_1">28. </span><span id="t3n_1" class="t v2_1 s1_1">¿Cuantos Embarazos__{{data?.emb_c}}_____________, Partos_{{data.part_c}}___________, </span>
<span id="t3o_1" class="t v2_1 s1_1">Abortos__{{data?.abort_c}}________ha tenido? </span>
<span id="t3p_1" class="t v2_1 s1_1">29. </span><span id="t3q_1" class="t v2_1 s1_1">¿ Estas embarazada, lactando o ha tenido abortos, legrados, partos o cesareas </span>
<span id="t3r_1" class="t v2_1 s1_1">en el ultimo año? ¿Cual?__{{data?.eao_ul_an_c}}__________________________ </span>
<span id="t3s_1" class="t v2_1 s2_1">PARA DILIGENCIAMIENTO POR PARTE DEL PERSONAL DEL BANCO DE SANGRE </span>
<span id="t3t_1" class="t v2_1 s1_1">Calidad y consistencia de las respuestas, aspecto general, comportamiento </span>
<span id="t3u_1" class="t v2_1 s1_1">y actitud del donante potencial. </span>
<span id="t3v_1" class="t v2_1 s1_1">___________ </span><span id="t3w_1" class="t v2_1 s1_1">_____________ </span>

<span id="t3x_1" class="t v2_1 s1_1">APTO  </span><span id="t3y_1" class="t v2_1 s1_1">NO APTO</span>
<!-- <span id="aptos" class="t v2_1 s1_1" >{{data?.diligenciamiento == 1 ? "X" : ""}}</span>
<span id="noaptos" class="t v2_1 s1_1" >{{data?.diligenciamiento == 0 || data?.diligenciamiento == null ? "X" : ""}}</span> -->


<span id="t40_1" class="t v4_1 s4_1">Código Donante: </span>

<span id="t42_1" class="t v5_1 s5_1">SI </span><span id="t43_1" class="t v5_1 s5_1">__ Por favor, continúe con la encuesta. </span>
<span id="leyosi" class="t v5_1 s5_1">{{data?.leyo == 1 ? "X" : ""}}</span>

<span id="t44_1" class="t v5_1 s5_1">NO__ Antes de continuar con el diligenciamiento de la encuesta lea la información y cualquier inquietud preguntela al personal del </span> 
<span id="t45_1" class="t v5_1 s5_1">banco de sangre. </span>
<span id="leyono" class="t v5_1 s5_1">{{data?.leyo == 0 ? "X" : ""}}</span>


<span id="t47_1" class="t v6_1 s6_1">1. NOMBRES Y APELLIDOS: </span> 
<span id="nomap" class="t v6_1 tam">{{data?.nombres}}  {{data?.apellidos}} </span> 

<span id="t48_1" class="t v6_1 s6_1">2. LUGAR Y FECHA DE NACIMIENTO:</span>
<span id="lugfcn" class="t v6_1 tam">{{data?.nom_lugar}}</span>
<span id="t4n_1" class="t v6_1 s6_1">Día</span><span id="t4o_1" class="t v6_1 s6_1">Mes</span><span id="t4p_1" class="t v6_1 s6_1">Año</span><span id="t4q_1" class="t v6_1 s6_1">años </span>

<span id="diaa" class="t v6_1 tam">{{nacimiento[2]}}</span>
<span id="mesaa" class="t v6_1 tam">{{nacimiento[1]}} </span>
<span id="anios" class="t v6_1 tam">{{nacimiento[0]}}</span> 

<span id="t49_1" class="t v6_1 s6_1">3. EDAD:</span>
<span id="eda" class="t v6_1 tam">{{edad}}</span>

<span id="t4a_1" class="t v6_1 s6_1">6.DOCUMENTO DE IDENTIDAD: </span>

<span id="t4b_1" class="t v7_1 s6_1">7: GRUPO SANGUÍNEO</span>
<span id="gsan" class="t v7_1 tam">{{gruposan[0]}}</span>

<span id="t4c_1" class="t v6_1 s6_1">9. NOMBRE ASEGURADOR </span>
<span id="nomase" class="t v6_1 tam">{{data?.nom_ent}}</span>

<span id="t4d_1" class="t v6_1 s6_1">10. LUGAR DE LA DONACIÓN (CIUDAD O MUNICIPIO)</span>
<span id="lugdon" class="t v6_1 tam">{{data?.lugarDonacioness}} </span>

<span id="t4e_1" class="t v6_1 s6_1">11. DIRECCIÓN DE LA RESIDENCIA: </span>
<span id="dr" class="t v6_1 tam">{{data?.direccion_residencia}}</span>

<span id="t4f_1" class="t v6_1 s6_1">13. MUNICIPIO: </span>
<span id="munis" class="t v6_1 tam">{{data?.nom_muni}} </span>

<span id="t4g_1" class="t v6_1 s6_1">16. TELÉFONO ADICIONAL:</span>
<span id="teladi" class="t v6_1 tam">{{data?.telefono_fijo}}</span>

<span id="t4h_1" class="t v6_1 s6_1">19.CORREO ELECTRÓNICO</span>
<span id="corre" class="t v6_1 tam">{{data?.correo_electronico}}</span>

<span id="t4i_1" class="t v6_1 s6_1">14. DEPARTAMENTO </span>
<span id="dpd" class="t v6_1 tam">{{data?.dapartamentos}}</span>

<span id="t4j_1" class="t v8_1 s6_1">15.TELÉFONO PRINCIPAL:</span>
<span id="tprin" class="t v8_1 tam">{{data?.celular}}</span>

<span id="t4k_1" class="t v6_1 s6_1">12. BARRIO </span>
<span id="brr" class="t v6_1 tam">{{data?.barrio}}</span>

<span id="t4l_1" class="t v6_1 s6_1">17.OCUPACIÓN O EMPLEO </span>
<span id="ocuemp" class="t v6_1 tam">{{data?.nom_profe}}</span>

<span id="t4m_1" class="t v9_1 s6_1">18.TELEFONO DEL TRABAJO </span>
<span id="tltra" class="t v9_1 tam">{{data.telefono_laboral}} </span>

<span id="t4r_1" class="t v10_1 s6_1">Cedula Ciudadanía <span style="margin-left: 15px;" >{{data?.tipo_documento == 1 ? "X" : ""}}</span> </span><span id="t4s_1" class="t s6_1">Tarjeta de identidad <span style="margin-left: 15px;" >{{data?.tipo_documento == 2 ? "X" : ""}}</span> </span><span id="t4t_1" class="t v11_1 s6_1">Cédula de extranjería <span style="margin-left: 12px;" >{{data?.tipo_documento == 3 ? "X" : ""}}</span> </span><span id="t4u_1" class="t v12_1 s6_1">Libreta Militar <span style="margin-left: 12px;" >{{data?.tipo_documento == 4 ? "X" : ""}}</span></span>
<span id="t4v_1" class="t v13_1 s6_1" data-mappings='[[22,"fi"]]'>Numero único de identiﬁcación Personal (NUIP) <span style="margin-left: 46px;" >{{data?.tipo_documento == 6 ? "X" : ""}}</span> </span><span id="t4w_1" class="t v14_1 s6_1">Numero del documento </span><span id="t4x_1" class="t v15_1 s6_1">otro Cual?  <span style="margin-left: 15px;">{{data?.otro_doc  ? data?.otro_doc : "" }}</span></span>

<span id="idennu" class="t v14_1 tam">{{data?.numero_documento}} </span>

<span id="t4y_1" class="t v16_1 s6_1">Factor RH  <span style="margin-left: 20px; font-size: small;">{{gruposan[1]}}</span></span>

<span id="t4z_1" class="t v17_1 s6_1">EPS-C  <span style="margin-left: 15px ;">{{data?.cod_reg == "EPS-C" ? "X" : "" }}</span></span><span id="t50_1" class="t v17_1 s6_1">EPS-S <span style="margin-left: 20px ;">{{data?.cod_reg == "EPS-S" ? "X" : "" }}</span> </span><span id="t51_1" class="t v17_1 s6_1">SISBEN <span style="margin-left: 18px ;">{{data?.cod_reg == "SISBEN" ? "X" : "" }}</span> </span><span id="t52_1" class="t v18_1 s6_1">RÉGIMEN ESPECIAL <span style="margin-left: 20px ;">{{data?.cod_reg == "REGIMEN ESPECIAL" ? "X" : "" }}</span> </span><span id="t53_1" class="t v18_1 s6_1">NO TIENE <span style="margin-left: 18px ;">{{data?.cod_reg == "NO TIENE" ? "X" : "" }}</span> </span>
<span id="t54_1" class="t v6_1 s6_1">5.ESTADO CIVIL: </span><span id="t55_1" class="t v19_1 s5_1">Casado (a) <span style="margin-left: 13px;" >{{data?.cod_civils == "C" ? "X" : ""}}</span> </span><span id="t56_1" class="t s6_1">Unión Libre  <span style="margin-left: 10px;" >{{data?.cod_civils == "U" ? "x" : ""}}</span> </span><span id="t57_1" class="t v20_1 s6_1">Separado(a) <span style="margin-left: 15px;" >{{data?.cod_civils == "D" ? "X" : ""}}</span> </span><span id="t58_1" class="t v2_1 s6_1">Soltero(a) <span style="margin-left: 10px;" >{{data?.cod_civils == "S" ? "X" : ""}}</span> </span><span id="t59_1" class="t v21_1 s6_1">Viudo(a)  <span style="margin-left: 10px;" >{{data?.cod_civils == "V" ? "X" : ""}}</span> </span>

<span id="t5a_1" class="t v22_1 s5_1">Día </span><span id="diaA" class="t v22_1 s5_1">{{dateObj?.dia}}</span>  
<span id="t5b_1" class="t v22_1 s5_1">Mes </span><span id="mesA" class="t v22_1 s5_1">{{dateObj?.mes}}</span>
<span id="t5c_1" class="t v22_1 s5_1">Año </span><span id="anioA" class="t v22_1 s5_1">{{dateObj?.anio}}</span>

<span id="t5d_1" class="t v22_1 s5_1">Código Nacional: </span>
<span id="t5e_1" class="t v23_1 s7_1">08-001-14 </span>
<span id="t5f_1" class="t v1_1 s6_1">Vigente desde: </span>
<span id="t5g_1" class="t v1_1 s6_1">05-07-2023 </span>
<span id="t5h_1" class="t v1_1 s5_1">Versión: </span>
<span id="t5i_1" class="t v1_1 s5_1">10 </span>
<span id="t5j_1" class="t v1_1 s5_1">FO-SD-03 </span> 
<span id="t5k_1" class="t v24_1 s8_1">CRA. 50 No. 80-192 </span><span id="t5l_1" class="t v24_1 s8_1">Tel.: 304 2121 </span><span id="t5m_1" class="t v24_1 s8_1">PBX: 304 3030 </span>
<span id="t5n_1" class="t v25_1 s6_1">WWW.</span><span id="t5o_1" class="t v25_1 s4_1">banalsa.com.co </span>
<span id="t5p_1" class="t v26_1 s6_1">gerencia@banalsa.com.co </span>
<span id="t5q_1" class="t s9_1">Banco Nacional de sangre S.A.S </span>
<span id="t5r_1" class="t v6_1 s6_1">4: GENERO: </span>
<span id="t5s_1" class="t v6_1 s6_1">Masculino <span style="margin-left: 8px;"> {{data?.genero == "Masculino" ? "X" : ""}}</span> </span>
<span id="t5t_1" class="t v27_1 s6_1">Femenino <span style="margin-left: 7px;"> {{data?.genero == "Femenino" ? "X" : ""}}</span> </span>

<span id="t5u_1" class="t v6_1 s6_1">IPS de Atención <samp style="margin-left: 10px; font-size: small;">{{data?.ips_atencion}}</samp> </span>

<span id="t5v_1" class="t v6_1 s6_1">20.TELEFONO DE UN CONTACTO</span>
<span id="telcon" class="t v6_1 tam">{{data?.telefono_contacto}}</span>

<span id="t5w_1" class="t v28_1 s6_1">ESTIMADO DONANTE DE SANGRE. Gracias por su colaboración nuestro objetivo es proteger la salud del receptor - paciente quien recibe su sangre, como también </span>
<span id="t5x_1" class="t v28_1 s6_1">proteger su salud por tal razón en algunas ocasiones nos vemos en el deber de no aceptar una donación. Le pedimos el favor de contestar de la manera mas SINCERA el </span>
<span id="t5y_1" class="t v28_1 s6_1">siguiente cuestionario y recuerde que esta información es totalmente CONFIDENCIAL no olvide que este cuestionario se diligencia de manera voluntaria, no es </span>
<span id="t5z_1" class="t v28_1 s6_1">discriminatorio, solo pretende preservar las condiciones mas optimas de salud para la persona que recibe su sangre. Si usted esta o estuvo expuesto a situaciones de </span>
<span id="t60_1" class="t v28_1 s6_1">riesgo, no debería donar. No olvide decir la verdad, si mientras dona decide que su sangre no debe ser utilizada, por favor avisenos o diligencie el formato de autoexclusion </span>
<span id="t61_1" class="t v28_1 s6_1" data-mappings='[[3,"fi"]]'>conﬁdencial IMPORTANTE PARA NOSOTROS! Usted puede retirarse sin entregar el formulario si no esta de acuerdo con alguna de las preguntas. </span>
<span id="t62_1" class="t v28_1 s6_1" data-mappings='[[66,"fi"]]'>Si Usted diligencia esta Encuesta da autorización de consultar conﬁdencialmente en la base de datos del Banco de Sangre y del aplicativo </span>
<span id="t63_1" class="t v28_1 s6_1">SIHEVI-INS para constatar las fechas de donaciones previas y así poder tomar la decisión de continuar o no con el proceso de donación de sangre. </span>
<span id="t64_1" class="t v6_1 s6_1">21.NOMBRE O USUARIO QUE USA EN REDES SOCIALES: </span>
<span id="t65_1" class="t v29_1 s6_1">Pasaporte <span style="margin-left: 10px;" >{{data?.tipo_documento == 5 ? "X" : ""}}</span> </span>
<span id="t66_1" class="t v30_1 s6_1">8. AFILIACIÓN AL SISTEMA DE SALUD: </span></div>


</div>
</body>
</html>

<br>
<br>
<br>
<!DOCTYPE html>
<html>
<head>
<meta http-equiv="X-UA-Compatible" content="IE=Edge" />
<meta charset="utf-8" />
</head>

<body style="margin: 0;">

<div id="p2" style="overflow: hidden; position: relative; background-color: white; width: 935px; height: 1429px;">

<style class="shared-css" type="text/css" >
.t {
	transform-origin: bottom left;
	z-index: 2;
	position: absolute;
	white-space: pre;
	overflow: visible;
	line-height: 1.5;
}
.text-container {
	white-space: pre;
}
@supports (-webkit-touch-callout: none) {
	.text-container {
		white-space: normal;
	}
}
</style>

<style type="text/css" >

#t1_2{left:208px;bottom:854px;letter-spacing:-0.01px;word-spacing:0.08px;}
#t2_2{left:63px;bottom:815px;letter-spacing:0.13px;}
#t3_2{left:212px;bottom:814px;letter-spacing:0.13px;}
#t4_2{left:707px;bottom:793px;letter-spacing:-0.22px;}
#t5_2{left:604px;bottom:793px;letter-spacing:-0.2px;}
#t6_2{left:327px;bottom:814px;letter-spacing:-0.21px;}
#t7_2{left:532px;bottom:814px;letter-spacing:-0.21px;}
#t8_2{left:622px;bottom:814px;letter-spacing:-0.27px;}
#t9_2{left:735px;bottom:814px;letter-spacing:-0.27px;}
#ta_2{left:64px;bottom:793px;letter-spacing:0.23px;}
#tb_2{left:57px;bottom:753px;letter-spacing:0.26px;}
#tb_2_X{left:80px;bottom:725px;letter-spacing:0.26px;}
#tc_2{left:163px;bottom:754px;letter-spacing:-0.15px;}
#tc_2_X{left:187px;bottom:725px;letter-spacing:-0.15px;}
#td_2{left:267px;bottom:754px;letter-spacing:-0.15px;}
#te_2{left:272px;bottom:740px;letter-spacing:-0.04px;}
#te_2_X{left:290px;bottom:725px;letter-spacing:-0.04px;}
#tf_2{left:475px;bottom:755px;letter-spacing:0.01px;}
#tg_2{left:468px;bottom:743px;letter-spacing:0.01px;}
#tg_2_X{left:490px;bottom:725px;letter-spacing:0.01px;}
#th_2{left:558px;bottom:756px;letter-spacing:-0.12px;}
#ti_2{left:555px;bottom:746px;letter-spacing:-0.13px;}
#ti_2_X{left:580px;bottom:725px;letter-spacing:-0.13px;}
#tj_2{left:662px;bottom:759px;letter-spacing:0.2px;word-spacing:0.01px;}
#tk_2{left:662px;bottom:748px;letter-spacing:0.2px;}
#tl_2{left:52px;bottom:682px;letter-spacing:0.08px;word-spacing:-0.43px;}
#tm_2{left:65px;bottom:667px;letter-spacing:0.26px;}
#tn_2{left:62px;bottom:653px;letter-spacing:0.21px;}
#to_2{left:64px;bottom:638px;letter-spacing:0.2px;}
#to_2_S{left:35px;bottom:623px;letter-spacing:0.2px;}
#to_2_D{left:85px;bottom:623px;letter-spacing:0.2px;}
#tp_2{left:157px;bottom:682px;letter-spacing:0.22px;}
#tq_2{left:139px;bottom:667px;letter-spacing:0.19px;}
#tr_2{left:150px;bottom:653px;letter-spacing:0.2px;}
#tr_2_P{left:160px;bottom:624px;letter-spacing:0.2px;}
#ts_2{left:230px;bottom:682px;letter-spacing:-0.03px;}
#tt_2{left:240px;bottom:667px;letter-spacing:0.23px;}
#tu_2{left:226px;bottom:653px;letter-spacing:0.2px;}
#tu_2_T{left:238px;bottom:624px;letter-spacing:0.2px;}
#tv_2{left:282px;bottom:681px;letter-spacing:0.21px;}
#tw_2{left:289px;bottom:652px;letter-spacing:0.21px;}
#tw_2_PES{left:300px;bottom:624px;letter-spacing:0.21px;}
#tx_2{left:355px;bottom:682px;letter-spacing:-0.09px;}
#ty_2{left:359px;bottom:667px;letter-spacing:0.21px;}
#ty_2_ME{left:359px;bottom:623px;letter-spacing:0.21px;}
#tz_2{left:408.6px;bottom:641.4px;}
#t10_2{left:462px;bottom:682px;letter-spacing:0.04px;}
#t11_2{left:474px;bottom:665px;letter-spacing:0.19px;}
#t12_2{left:457px;bottom:648px;letter-spacing:0.2px;}
#t12_2_VOLE{left:457px;bottom:624px;letter-spacing:0.2px;}
#t13_2{left:463.7px;bottom:641px;}
#t14_2{left:529px;bottom:682px;letter-spacing:0.23px;}
#t15_2{left:532px;bottom:670px;letter-spacing:-0.16px;}
#t16_2{left:531px;bottom:657px;letter-spacing:-0.16px;}
#t16_2_HEMOG{left:531px;bottom:625px;letter-spacing:-0.16px;}
#t17_2{left:619px;bottom:682px;letter-spacing:0.22px;}
#t18_2{left:619px;bottom:670px;letter-spacing:-0.17px;}
#t19_2{left:618px;bottom:657px;letter-spacing:-0.17px;}
#t19_2_HEMAT{left:618px;bottom:625px;letter-spacing:-0.17px;}
#t1a_2{left:722px;bottom:685px;letter-spacing:-0.21px;}
#t1b_2{left:360px;bottom:588px;letter-spacing:0.2px;}
#t1b_2_DIFER{left:360px;bottom:573px;letter-spacing:0.2px;}
#t1c_2{left:58px;bottom:589px;letter-spacing:0.23px;}
#t1d_2{left:165px;bottom:588px;letter-spacing:0.19px;}
#t1e_2{left:57px;bottom:575px;letter-spacing:-0.2px;}
#t1f_2{left:88px;bottom:575px;letter-spacing:-0.32px;}
#t1g_2{left:207px;bottom:577px;letter-spacing:-0.24px;}
#t1h_2{left:140px;bottom:577px;letter-spacing:-0.33px;}
#t1i_2{left:660px;bottom:588px;letter-spacing:0.21px;}
#t1j_2{left:36px;bottom:537px;letter-spacing:0.19px;word-spacing:0.01px;}
#t1j_2_BOL{left:36px;bottom:523px;letter-spacing:0.19px;word-spacing:0.01px;}
#t1k_2{left:228px;bottom:537px;letter-spacing:0.22px;}
#t1k_2_LOT{left:228px;bottom:520px;letter-spacing:0.22px;}
#t1l_2{left:662px;bottom:537px;letter-spacing:0.22px;}
#t1m_2{left:391px;bottom:538px;letter-spacing:0.21px;}
#t1m_2_FECHV{left:391px;bottom:520px;letter-spacing:0.21px;}
#t1n_2{left:61px;bottom:483px;letter-spacing:0.2px;}
#t1o_2{left:62px;bottom:466px;letter-spacing:0.02px;}
#t1p_2{left:98px;bottom:466px;letter-spacing:0.03px;}
#t1q_2{left:61px;bottom:452px;letter-spacing:0.03px;}
#t1r_2{left:319px;bottom:484px;letter-spacing:-0.05px;word-spacing:0.06px;}
#t1s_2{left:479px;bottom:465px;letter-spacing:0.03px;}
#t1t_2{left:299px;bottom:465px;letter-spacing:0.03px;}
#t1u_2{left:299px;bottom:451px;letter-spacing:0.03px;}
#t1v_2{left:500px;bottom:484px;letter-spacing:-0.05px;word-spacing:0.05px;}
#t1w_2{left:477px;bottom:447px;letter-spacing:0.03px;}
#t1x_2{left:346px;bottom:465px;letter-spacing:0.02px;}
#t1y_2{left:704px;bottom:484px;letter-spacing:-0.03px;word-spacing:0.04px;}
#t1z_2{left:41px;bottom:831px;}
#t20_2{left:41px;bottom:773px;letter-spacing:-0.02px;word-spacing:0.02px;}
#t21_2{left:41px;bottom:702px;}
#t22_2{left:664px;bottom:773px;}
#t23_2{left:41px;bottom:604px;}
#t24_2{left:41px;bottom:502px;letter-spacing:-0.07px;word-spacing:0.06px;}
#t25_2{left:41px;bottom:554px;}
#t26_2{left:40px;bottom:425px;letter-spacing:0.06px;}
#t27_2{left:39px;bottom:1282px;letter-spacing:0.11px;word-spacing:0.01px;}
#t28_2{left:189px;bottom:1282px;letter-spacing:0.1px;word-spacing:-0.01px;}
#t29_2{left:39px;bottom:1269px;letter-spacing:0.08px;}
#t2a_2{left:103px;bottom:1269px;letter-spacing:0.08px;}
#t2b_2{left:33px;bottom:1255px;letter-spacing:0.08px;}
#t2c_2{left:379px;bottom:1255px;letter-spacing:0.19px;}
#t2d_2{left:434px;bottom:1255px;letter-spacing:0.08px;}
#t2e_2{left:33px;bottom:1241px;letter-spacing:0.08px;word-spacing:-0.02px;}
#t2f_2{left:39px;bottom:1229px;letter-spacing:0.08px;}
#t2g_2{left:33px;bottom:1217px;letter-spacing:0.08px;}
#t2h_2{left:39px;bottom:1205px;letter-spacing:0.08px;}
#t2i_2{left:33px;bottom:1193px;letter-spacing:0.08px;word-spacing:-0.02px;}
#t2j_2{left:39px;bottom:1181px;letter-spacing:0.09px;}
#t2k_2{left:33px;bottom:1169px;letter-spacing:0.07px;word-spacing:-0.01px;}
#t2l_2{left:39px;bottom:1156px;letter-spacing:0.08px;word-spacing:0.01px;}
#t2m_2{left:33px;bottom:1144px;letter-spacing:0.08px;word-spacing:-0.02px;}
#t2n_2{left:39px;bottom:1132px;letter-spacing:0.09px;}
#t2o_2{left:33px;bottom:1120px;letter-spacing:0.08px;word-spacing:-0.02px;}
#t2p_2{left:39px;bottom:1108px;letter-spacing:0.09px;}
#t2q_2{left:33px;bottom:1096px;letter-spacing:0.08px;word-spacing:-0.02px;}
#t2r_2{left:39px;bottom:1084px;letter-spacing:0.08px;}
#t2s_2{left:33px;bottom:1071px;letter-spacing:0.08px;word-spacing:-0.02px;}
#t2t_2{left:39px;bottom:1059px;letter-spacing:0.08px;}
#t2u_2{left:33px;bottom:1047px;letter-spacing:0.08px;word-spacing:-0.03px;}
#t2v_2{left:33px;bottom:1035px;letter-spacing:0.08px;}
#t2w_2{left:39px;bottom:1023px;letter-spacing:0.08px;}
#t2x_2{left:33px;bottom:1011px;letter-spacing:0.08px;word-spacing:-0.06px;}
#t2y_2{left:375px;bottom:974px;letter-spacing:0.09px;}
#t2z_2{left:716px;bottom:977px;letter-spacing:0.09px;}
#t30_2{left:183px;bottom:881px;letter-spacing:-0.2px;word-spacing:0.05px;}
#t31_2{left:702px;bottom:884px;letter-spacing:-0.17px;}
#t32_2{left:158px;bottom:364px;letter-spacing:0.03px;}
#t33_2{left:62px;bottom:366px;letter-spacing:0.02px;}
#t34_2{left:104px;bottom:366px;letter-spacing:0.03px;}
#t35_2{left:61px;bottom:409px;letter-spacing:0.19px;}
#t36_2{left:65px;bottom:396px;letter-spacing:0.2px;}
#t37_2{left:148px;bottom:384px;letter-spacing:0.2px;}
#t38_2{left:386px;bottom:409px;letter-spacing:-0.07px;word-spacing:0.08px;}
#t39_2{left:427px;bottom:395px;}
#t39_2_VOLU{left:427px;bottom:365px;}
#t3a_2{left:537px;bottom:409px;letter-spacing:-0.02px;word-spacing:0.02px;}
#t3b_2{left:518px;bottom:388px;letter-spacing:0.09px;}
#t3c_2{left:602px;bottom:388px;letter-spacing:0.09px;}
#t3d_2{left:579px;bottom:365px;}
#t3e_2{left:702px;bottom:409px;}
#t3f_2{left:38px;bottom:326px;letter-spacing:0.03px;}
#t3g_2{left:64px;bottom:313px;letter-spacing:0.03px;}
#t3h_2{left:169px;bottom:326px;letter-spacing:0.03px;}
#t3i_2{left:209px;bottom:313px;letter-spacing:0.02px;}
#t3i_2_LOTTIPE{left:209px;bottom:280px;letter-spacing:0.02px;}
#t3j_2{left:320px;bottom:332px;letter-spacing:-0.12px;word-spacing:0.04px;}
#t3k_2{left:352px;bottom:321px;letter-spacing:-0.08px;}
#t3l_2{left:332px;bottom:310px;letter-spacing:-0.07px;}
#t3l_2_VENAFE{left:332px;bottom:280px;letter-spacing:-0.07px;}
#t3m_2{left:79px;bottom:290px;letter-spacing:0.03px;}
#t3n_2{left:80px;bottom:271px;letter-spacing:0.03px;}
#t3o_2{left:80px;bottom:253px;letter-spacing:0.02px;}
#t3p_2{left:434px;bottom:332px;letter-spacing:-0.35px;}
#t3q_2{left:430px;bottom:321px;letter-spacing:-0.19px;}
#t3r_2{left:447px;bottom:310px;letter-spacing:-0.13px;}
#t3r_2_COLFA{left:447px;bottom:280px;letter-spacing:-0.13px;}
#t3s_2{left:517px;bottom:327px;letter-spacing:0.1px;}
#t3t_2{left:510px;bottom:314px;letter-spacing:0.1px;}
#t3u_2{left:708px;bottom:314px;letter-spacing:0.09px;}
#t3v_2{left:655px;bottom:258px;letter-spacing:0.02px;}
#t3v_2_PLAQ{left:520px;bottom:258px;letter-spacing:0.02px;}
#t3w_2{left:738px;bottom:268px;}
#t3x_2{left:769px;bottom:328px;letter-spacing:0.1px;word-spacing:-0.5px;}
#t3y_2{left:71px;bottom:211px;letter-spacing:0.02px;}
#t3y_2_XRADSI{left:71px;bottom:195px;letter-spacing:0.02px;}
#t3z_2{left:134px;bottom:211px;letter-spacing:0.03px;}
#t3z_2XRADNO{left:134px;bottom:195px;letter-spacing:0.03px;}
#t40_2{left:203px;bottom:197px;letter-spacing:-0.31px;}
#t41_2{left:146px;bottom:183px;letter-spacing:-0.23px;}
#t42_2{left:378px;bottom:197px;letter-spacing:-0.31px;}
#t43_2{left:478px;bottom:215px;letter-spacing:-0.06px;word-spacing:0.03px;}
#t44_2{left:562px;bottom:198px;letter-spacing:-0.27px;word-spacing:0.05px;}
#t45_2{left:51px;bottom:146px;letter-spacing:0.16px;}
#t46_2{left:51px;bottom:129px;letter-spacing:0.15px;}
#t47_2{left:116px;bottom:108px;letter-spacing:0.02px;}
#t48_2{left:52px;bottom:108px;letter-spacing:0.03px;}
#t49_2{left:166px;bottom:108px;letter-spacing:0.03px;}
#t4a_2{left:180px;bottom:50px;letter-spacing:-0.04px;word-spacing:0.06px;}
#t4b_2{left:666px;bottom:50px;letter-spacing:0.02px;}

#t4c_2{left:171px;bottom:1353px;letter-spacing:-0.19px;}
#obser{left:27px;bottom:1330px;letter-spacing:-0.19px;}
#firmaobser{left:850px;bottom:1315px;letter-spacing:-0.19px;}

#t4d_2{left:305px;bottom:1300px;letter-spacing:-0.2px;}
#t4e_2{left:40px;bottom:344px;letter-spacing:0.06px;word-spacing:-0.53px;}
#t4f_2{left:40px;bottom:231px;letter-spacing:0.06px;}
#t4g_2{left:40px;bottom:162px;letter-spacing:0.03px;word-spacing:0.02px;}
#t4h_2{left:408px;bottom:681px;letter-spacing:0.25px;}
#t4i_2{left:396px;bottom:664px;letter-spacing:0.17px;}
#t4j_2{left:439px;bottom:664px;}
#t4k_2{left:402px;bottom:646px;letter-spacing:0.19px;}
#t4k_2_IMC{left:395px;bottom:624px;letter-spacing:0.19px;}
#t4l_2{left:433px;bottom:672px;}
#t4m_2{left:370px;bottom:754px;letter-spacing:-0.3px;}
#t4n_2{left:367px;bottom:741px;letter-spacing:-0.25px;}
#t4n_2_X{left:397px;bottom:723px;letter-spacing:-0.25px;}
#t4o_2{left:121px;bottom:974px;letter-spacing:0.08px;}
#t4o_2_FIR{left:121px;bottom:990px;letter-spacing:0.08px;}
#t4p_2{left:169px;bottom:908px;letter-spacing:-0.19px;}
#t4q_2{left:602px;bottom:906px;letter-spacing:0.08px;}
#t4r_2{left:309px;bottom:950px;letter-spacing:0.17px;word-spacing:0.1px;}
#t4s_2{left:750px;bottom:198px;letter-spacing:-0.22px;}
#t4t_2{left:51px;bottom:181px;letter-spacing:0.11px;}
#t4u_2{left:262px;bottom:183px;letter-spacing:-0.23px;}
#t4v_2{left:379px;bottom:183px;letter-spacing:-0.23px;}
#t4w_2{left:495px;bottom:183px;letter-spacing:-0.23px;}
#t4x_2{left:612px;bottom:183px;letter-spacing:-0.23px;}

.s0_2{font-size:17px;font-family:Arial-BoldMT_g;color:#231F20;}
.s1_2{font-size:14px;font-family:Arial-BoldMT_g;color:#231F20;}
.s2_2{font-size:13px;font-family:Arial-BoldMT_g;color:#231F20;}
.s3_2{font-size:12px;font-family:Arial-BoldMT_g;color:#231F20;}
.s4_2{font-size:11px;font-family:Arial-BoldMT_g;color:#231F20;}
.s5_2{font-size:15px;font-family:ArialMT_u;color:#231F20;}
.s6_2{font-size:19px;font-family:ArialMT_u;color:#231F20;}
.s7_2{font-size:10px;font-family:Arial-BoldMT_g;color:#231F20;}
.s8_2{font-size:15px;font-family:Arial-BoldMT_g;color:#FFF;}
.s9_2{font-size:14px;font-family:Arial-BoldMT_g;color:#FFF;}
.sa_2{font-size:12px;font-family:Arial-Black_n;color:#231F20;}
.sb_2{font-size:9px;font-family:Arial-BoldMT_g;color:#231F20;}
.sc_2{font-size:7px;font-family:Arial-BoldMT_g;color:#231F20;}
.sd_2{font-size:16px;font-family:Arial-BoldMT_g;color:#FFF;}
.se_2{font-size:14px;font-family:Arial-Black_n;color:#231F20;}
.t.v0_2{transform:scaleX(0.937);}
.t.v1_2{transform:scaleX(1.043);}
.t.v2_2{transform:scaleX(0.935);}
.t.v3_2{transform:scaleX(0.954);}
.t.v4_2{transform:scaleX(0.985);}
.t.v5_2{transform:scaleX(0.948);}
.t.v6_2{transform:scaleX(0.857);}
.t.v7_2{transform:scaleX(0.896);}
.t.v8_2{transform:scaleX(0.92);}
.t.v9_2{transform:scaleX(1.028);}
.t.v10_2{transform:scaleX(1.033);}
.t.v11_2{transform:scaleX(0.82);}
.t.v12_2{transform:scaleX(0.751);}
.t.v13_2{transform:scaleX(0.747);}
.t.v14_2{transform:scaleX(1.059);}
.t.v15_2{transform:scaleX(1.042);}
.t.v16_2{transform:scaleX(0.914);}
.t.v17_2{transform:scaleX(0.792);}
.t.v18_2{transform:scaleX(0.825);}
.t.v19_2{transform:scaleX(0.905);}
.t.v20_2{transform:scaleX(0.882);}
.t.v21_2{transform:scaleX(0.972);}
.t.v22_2{transform:scaleX(0.918);}
.t.v23_2{transform:scaleX(0.927);}
.t.v24_2{transform:scaleX(1.009);}
.t.v25_2{transform:scaleX(0.855);}
.t.v26_2{transform:scaleX(1.078);}
.t.v27_2{transform:scaleX(1.144);}
.t.v28_2{transform:scaleX(0.922);}
.t.v29_2{transform:scaleX(0.775);}
.t.v30_2{transform:scaleX(0.84);}
.t.v31_2{transform:scaleX(0.923);}
.t.v32_2{transform:scaleX(0.912);}
.t.v33_2{transform:scaleX(0.961);}
.t.v34_2{transform:scaleX(0.788);}
.t.v35_2{transform:scaleX(0.869);}
.t.v36_2{transform:scaleX(0.936);}
.t.v37_2{transform:scaleX(1.082);}
.t.v38_2{transform:scaleX(0.921);}
.t.v39_2{transform:scaleX(1.126);}
.t.v40_2{transform:scaleX(0.898);}
.t.v41_2{transform:scaleX(1.036);}
.t.v42_2{transform:scaleX(1.057);}
.t.v43_2{transform:scaleX(0.879);}
.t.v44_2{transform:scaleX(0.846);}
.t.v45_2{transform:scaleX(0.967);}
.t.m0_2{transform:matrix(1.439,-0.76,0.52,0.85,0,0);}
</style>

<style id="fonts2" type="text/css" >

@font-face {
	font-family: Arial-Black_n;
	src: url("../../../../assets/fonts/Arial-Black_n.woff") format("woff");
}

@font-face {
	font-family: Arial-BoldMT_g;
	src: url("../../../../assets/fonts/Arial-BoldMT_g.woff") format("woff");
}

@font-face {
	font-family: ArialMT_u;
	src: url("../../../../assets/fonts/ArialMT_u.woff") format("woff");
}

</style>

<div id="pg2Overlay" style="width:100%; height:100%; position:absolute; z-index:1; background-color:rgba(0,0,0,0); -webkit-user-select: none;"></div>
<div id="pg2" style="-webkit-user-select: none;"><object width="935" height="1429" data="assets/2.svg" type="image/svg+xml" id="pdf2" style="width:935px; height:1429px; -moz-transform:scale(1); z-index: 0;"></object></div>




<div class="text-container"><span id="t1_2" class="t v0_2 s0_2">FICHA CLÍNICA DEL DONANTE </span> 
<span id="t2_2" class="t v1_2 s1_2">Sangre total <span style="margin-left: 11%;" >{{data?.sang_afer == 1 ? "X" : ""}}</span> </span><span id="t3_2" class="t v2_2 s1_2">Aféresis <span style="margin-left: 18%;" >{{data?.sang_afer == 2 ? "X" : ""}}</span> </span>
<span id="t4_2" class="t v3_2 s1_2">Extramural <span style="margin-left: 11%;" >{{data?.int_ext == 2 ? "X" : ""}}</span> </span><span id="t5_2" class="t v3_2 s1_2">Intramural  <span style="margin-left: 9%;" >{{data?.int_ext == 1 ? "X" : ""}}</span> </span>
<span id="t6_2" class="t v4_2 s1_2">Fecha de la donación: </span><span id="t7_2" class="t v3_2 s1_2">Dia {{fechDona ? fechDona[2] : "" }} </span><span id="t8_2" class="t v3_2 s1_2">Mes {{fechDona ? fechDona[1] : "" }} </span><span id="t9_2" class="t v3_2 s1_2">Año {{fechDona ? fechDona[0] : "" }}  </span>
<span id="ta_2" class="t v5_2 s2_2">Lugar de la donación: {{data?.lugar_de_donacion}} </span>
<span id="tb_2" class="t v6_2 s2_2">Autólogo   </span> <span id="tb_2_X" class="t v6_2 s2_2">{{data?.tipo_donante == 63 ? "X" : ""}}</span> <span id="tc_2" class="t v7_2 s2_2">Voluntario  </span><span id="tc_2_X" class="t v6_2 s2_2">{{data?.tipo_donante == 64 ? "X" : ""}}</span>  <span id="td_2" class="t v6_2 s2_2">Voluntario </span> 
<span id="te_2" class="t v6_2 s2_2">Habitual </span> <span id="te_2_X" class="t v6_2 s2_2">{{data?.tipo_donante == 65 ? "X" : ""}}</span>  
<span id="tf_2" class="t v8_2 s3_2">Dirigido </span>
<span id="tg_2" class="t v8_2 s3_2" data-mappings='[[6,"fi"]]'>Especiﬁco </span> <span id="tg_2_X" class="t v8_2 s3_2" data-mappings='[[6,"fi"]]'>{{data?.tipo_donante == 67 ? "X" : ""}}</span>
<span id="th_2" class="t v9_2 s4_2">Dador por </span>
<span id="ti_2" class="t v9_2 s4_2">Reposición </span> <span id="ti_2_X" class="t v9_2 s4_2">{{data?.tipo_donante == 68 ? "X" : ""}}</span>
<span id="tj_2" class="t v10_2 s4_2">Tiempo transcurrido desde la última </span>
<span id="tk_2" class="t v10_2 s4_2">ingesta de alimento (horas): {{data?.hor_ing_alim}}</span> 
<span id="tl_2" class="t v6_2 s1_2">Ten. Arterial </span>
<span id="tm_2" class="t v6_2 s1_2">(mmHg) </span>
<span id="tn_2" class="t v6_2 s1_2">S:90-170 </span>
<span id="to_2" class="t v6_2 s1_2">D: 60-90 </span> <span *ngIf="tipe == 1" id="to_2_S" class="t v6_2 s1_2">S - {{data?.ten_art_s}}</span> <span *ngIf="tipe == 1" id="to_2_D" class="t v6_2 s1_2">D - {{data?.ten_art_d}}</span> 
<span id="tp_2" class="t v6_2 s1_2">Pulso </span>
<span id="tq_2" class="t v6_2 s1_2">(latidos/mn) </span>
<span id="tr_2" class="t v6_2 s1_2">50 a 100 </span> <span id="tr_2_P" class="t v6_2 s1_2">{{data?.pulso}} </span> 
<span id="ts_2" class="t v6_2 s1_2">Temp. </span> 
<span id="tt_2" class="t v6_2 s1_2">°C </span>
<span id="tu_2" class="t v6_2 s1_2">&lt; 37° C </span> <span id="tu_2_T" class="t v6_2 s1_2">{{data?.temp_c}}</span>  
<span id="tv_2" class="t v6_2 s1_2">Peso (Kg) </span>
<span id="tw_2" class="t v6_2 s1_2">&gt; 50 Kg </span> <span *ngIf="tipe == 1" id="tw_2_PES" class="t v6_2 s1_2">{{data?.peso_kg}} Kg</span>
<span id="tx_2" class="t v6_2 s1_2">Talla </span>
<span id="ty_2" class="t v6_2 s1_2">(m) </span> <span *ngIf="tipe == 1" id="ty_2_ME" class="t v6_2 s1_2">{{data?.talla_cm}} M</span>
<span id="tz_2" class="t m0_2 s5_2">- </span>
<span id="t10_2" class="t v6_2 s1_2">Volemia </span>
<span id="t11_2" class="t v6_2 s1_2">(ml) </span>
<span id="t12_2" class="t v6_2 s1_2">&gt; 3500 ml </span> <span *ngIf="tipe == 1" id="t12_2_VOLE" class="t v6_2 s1_2">{{data?.volemia}} ml</span>
<span id="t13_2" class="t m0_2 s6_2">- </span>
<span id="t14_2" class="t v11_2 s1_2">Hemoglobina </span>
<span id="t15_2" class="t v12_2 s3_2">(H:13 - 18.5 gr/dl) </span>
<span id="t16_2" class="t v12_2 s3_2">(M:12 - 16.5 gr/dl) </span> <span *ngIf="tipe == 1" id="t16_2_HEMOG" class="t v15_2 s7_2">{{data?.hemoglo}} gr/dl </span>
<span id="t17_2" class="t v11_2 s1_2">Hematocrito </span>
<span id="t18_2" class="t v13_2 s3_2">(H:39.0 - 55.5 %) </span>
<span id="t19_2" class="t v13_2 s3_2">(M:36.0 - 49.5 %) </span> <span *ngIf="tipe == 1" id="t19_2_HEMAT" class="t v13_2 s3_2">{{data?.hematoc}} %</span>    
<span id="t1a_2" class="t v14_2 s3_2" data-mappings='[[19,"fi"]]'>Responsable examen ﬁsico </span>
<span id="t1b_2" class="t v6_2 s1_2">Motivo de diferimiento </span> <span id="t1b_2_DIFER" class="t v6_2 s1_2">{{data?.moti_diferimientos}}</span>   <span id="t1c_2" class="t v6_2 s1_2">Aceptado </span><span id="t1d_2" class="t v6_2 s1_2">Diferido </span>
<span id="t1e_2" class="t v6_2 s7_2">SI <span style="margin-left: 5px;" >{{data?.estado == "Aceptado" ? "X" : ""}}</span> </span><span id="t1f_2" class="t v6_2 s7_2">NO <span *ngIf="tipe == 1" style="margin-left: 10px;" >{{data?.estado !="Aceptado" ? "X" : ""}}</span> </span><span id="t1g_2" class="t v15_2 s7_2">Permanente  <span style="margin-left: 8%;" >{{data?.estado == "Diferido Permanente" ? "X" : ""}}</span> </span><span id="t1h_2" class="t v15_2 s7_2">Temporal <span style="margin-left: 5px;" >{{data?.estado == "Diferido Temporal" ? "X" : ""}}</span> </span> 
<span id="t1i_2" class="t v16_2 s1_2">Responsable entrevista </span>
<span id="t1j_2" class="t v17_2 s1_2">Clase de bolsa (marca y Tipo)  </span><span id="t1k_2" class="t v18_2 s1_2">Número de Lote a </span><span id="t1l_2" class="t v19_2 s1_2">Responsable marcación </span><span id="t1m_2" class="t v19_2 s1_2">Fecha de vencimiento </span>   
<span id="t1j_2_BOL" class="t v17_2 s1_2">{{data?.tipe_bol}}</span>
<span id="t1k_2_LOT" class="t v18_2 s1_2">{{data?.num_lot}}</span>
<span id="t1m_2_FECHV" class="t v19_2 s1_2">{{data?.fec_ven_bv}}</span> 
<span id="t1n_2" class="t v20_2 s1_2">Lesiones en sitio venopunción </span>
<span id="t1o_2" class="t v21_2 s2_2">SI {{data?.les_st_mar == "SI" ? "X" : ""}}</span><span id="t1p_2" class="t v21_2 s2_2">NO {{data?.les_st_mar == "NO" ? "X" : ""}}</span> 
<span id="t1q_2" class="t v21_2 s2_2" data-mappings='[[6,"fi"]]'>Especiﬁque: {{data?.les_st_mar_c}}</span>
<span id="t1r_2" class="t v22_2 s1_2">Venopunción única </span>
<span id="t1s_2" class="t s2_2">Brazo izquierdo <span style="margin-left: 5%;" >{{data?.sit_ven == 1 ? "X" : ""}}</span></span><span id="t1t_2" class="t v21_2 s2_2">NO {{data?.ven_u == "NO" ? "X" : ""}}</span>
<span id="t1u_2" class="t v21_2 s2_2">Cuantas? {{data?.ven_u_c}}</span>
<span id="t1v_2" class="t v23_2 s1_2">Sitio de Venopunción </span>
<span id="t1w_2" class="t v24_2 s2_2">Brazo derecho <span style="margin-left: 14%;" >{{data?.sit_ven == 2 ? "X" : ""}}</span></span>
<span id="t1x_2" class="t v21_2 s2_2">SI {{data?.ven_u == "SI" ? "X" : ""}}</span>
<span id="t1y_2" class="t v25_2 s1_2">Responsable de Venopunción </span>








<span id="t27_2" class="t v28_2 sa_2">APRECIADO DONANTE: </span><span id="t28_2" class="t v28_2 sa_2" data-mappings='[[9,"fi"]]'>Antes de ﬁrmar la encuesta, por favor lea con detenimiento los siguientes párrafos: </span>
<span id="t29_2" class="t v28_2 s3_2">Para todos </span><span id="t2a_2" class="t v28_2 s3_2">los efectos legales: </span>
<span id="t2b_2" class="t v28_2 s3_2">- Para evitar que mi sangre pueda ocasionar un daño al receptor </span><span id="t2c_2" class="t v28_2 sa_2" data-mappings='[[5,"fi"]]'>certiﬁco </span><span id="t2d_2" class="t v28_2 s3_2">que todas las respuestas e informes anotados en el presente cuestionario son veraces. </span>
<span id="t2e_2" class="t v28_2 s3_2">- Autorizo al banco de sangre, de manera libre, voluntaria, previa, explicita, informada e inequívoca, para que en los términos legalmente establecidos realice </span>
<span id="t2f_2" class="t v28_2 s3_2">la recolección, almacenamiento, uso, circulación, supresión y en general, el tratamiento de los datos personales que he precedido a entregar . </span>
<span id="t2g_2" class="t v28_2 s3_2" data-mappings='[[99,"fi"]]'>- Dono mi sangre de manera responsable y voluntaria a este banco de sangre para que la utilice con ﬁnes terapéuticos o de investigación y que si los resultados </span>
<span id="t2h_2" class="t v28_2 s3_2">de la misma lo requieren, seré informado sobre ello. </span>
<span id="t2i_2" class="t v28_2 s3_2">- Autorizo a ser ingresado en la base nacional de donantes de sangre y ser consultado a través del aplicativo SIHEVI-INS por personal autorizado para acceder </span>
<span id="t2j_2" class="t v28_2 s3_2">al mismo. </span>
<span id="t2k_2" class="t v28_2 s3_2" data-mappings='[[110,"fi"]]'>- Autorizo que se realice a mi sangre las pruebas para marcadores de: VIH I - II; Hepatitis B, Hepatitis C, Síﬁlis, Enfermedad de Chagas, HTLV I - II, Core y otras </span>
<span id="t2l_2" class="t v28_2 s3_2">que pudiesen ser requeridas para evitar que esta sangre pueda ocasionar un daño al receptor. </span>
<span id="t2m_2" class="t v28_2 s3_2" data-mappings='[[146,"fi"]]'>- Autorizo para que en caso de que uno o mas resultados de la prueba realizadas en el banco de sangre sean reactivas, se realice la respectiva conﬁrmación </span>
<span id="t2n_2" class="t v28_2 s3_2">o complemantación, según las normas establecidas. </span>
<span id="t2o_2" class="t v28_2 s3_2">- Autorizo para que en caso de ser necesario y de acuerdo a las normas establecidas se me ubique e informe mediante asesoría personalizada los hallazgo </span>
<span id="t2p_2" class="t v28_2 s3_2" data-mappings='[[28,"fi"]]'>obtenidos en las pruebas conﬁrmatorias. </span>
<span id="t2q_2" class="t v28_2 s3_2">- Autorizo para que en caso de ser necesario y de acuerdo a las normas establecidas se informe al servicio de epidemiologia correspondiente y a mi aseguradora </span>
<span id="t2r_2" class="t v28_2 s3_2" data-mappings='[[52,"fi"]]'>en salud, los hallazgos obtenidos en las pruebas conﬁrmatorias. </span>
<span id="t2s_2" class="t v28_2 s3_2" data-mappings='[[55,"fi"]]'>- Autorizo para que mi donación pueda ser empleada con ﬁnes de investigación, y en caso de ser necesario seré informado de los resultados de dichas </span>
<span id="t2t_2" class="t v28_2 s3_2">investigaciones. </span>
<span id="t2u_2" class="t v28_2 s3_2">- Autorizo utilizar mi correo electrónico para recibir información referente a Banco de Sangre. </span>
<span id="t2v_2" class="t v28_2 s3_2">- Dejo constancia de estar informado(a) sobre el proceso de donación, de las reacciones adversas que puedo sufrir durante o después del proceso y de la </span>
<span id="t2w_2" class="t v28_2 s3_2">donación y de las recomendaciones que debo seguir para evitarlas . </span>
<span id="t2x_2" class="t v28_2 s3_2" data-mappings='[[34,"fi"]]'>- Autorizo utilizar mi plasma con ﬁnes industriales. </span>
<span id="t2y_2" class="t v29_2 s3_2">Número de Documento de Identidad. </span>
<span id="t2z_2" class="t v30_2 s3_2">Lugar de Expedición </span>
<span id="t30_2" class="t v31_2 s3_2">POR FAVOR NO MARQUE LAS SIGUIENTES CASILLAS </span>
<span id="t31_2" class="t v31_2 s3_2">CODIGO DE BARRAS </span>
<span id="t32_2" class="t v21_2 s2_2" data-mappings='[[6,"fi"]]'>Especiﬁque: {{data?.ig_iden_por}}</span>
<span id="t33_2" class="t v21_2 s2_2">SI <span style="margin-left: 15%;">{{data?.ig_iden == "SI" ? "X" : ""}}</span></span><span id="t34_2" class="t v21_2 s2_2">NO <span style="margin-left: 18%;" >{{data?.ig_iden == "NO" ? "X" : ""}}</span></span>
<span id="t35_2" class="t v32_2 s1_2" data-mappings='[[19,"fi"]]'>¿Es igual la identiﬁcación de la encuesta, </span> 
<span id="t36_2" class="t v32_2 s1_2">bolsa, tubos y formato de autoexclusión </span>
<span id="t37_2" class="t v32_2 s1_2" data-mappings='[[3,"fi"]]'>conﬁdencial? </span>
<span id="t38_2" class="t v22_2 s1_2">Volumen extraído </span>
<span id="t39_2" class="t v22_2 s1_2">(ml) </span>
<span id="t39_2_VOLU" class="t v22_2 s1_2">{{data?.vol_ext}}  (ml) </span>
<span id="t3a_2" class="t v22_2 s1_2">Tiempo de duración </span>
<span id="t3b_2" class="t v33_2 sb_2">Hora inicio: <span style="font-size: small;">{{data?.hora_i}}</span></span><span id="t3c_2" class="t v33_2 sb_2" data-mappings='[[5,"fi"]]'>Hora ﬁnal: <span style="font-size: small;">{{data?.hora_f}}</span> </span>
<span id="t3d_2" class="t v34_2 s1_2"> {{data?.dur_min}} Minutos</span>
<span id="t3e_2" class="t v22_2 s1_2">Responsable y ﬂebotomia </span>
<span id="t3f_2" class="t v21_2 s2_2">Hemocomponantes </span>
<span id="t3g_2" class="t v21_2 s2_2">colectados </span>
<span id="t3h_2" class="t v35_2 s2_2" data-mappings='[[6,"fi"]]'>Identiﬁcacion de la bolsa </span>
<span id="t3i_2" class="t v35_2 s2_2">(tipo, lote) </span>
<span id="t3i_2_LOTTIPE" class="t v35_2 s2_2">{{data?.iden_bol}} </span>
<span id="t3j_2" class="t v36_2 s4_2">Fecha Vencimiento </span>
<span id="t3k_2" class="t v36_2 s4_2">Bolsa </span>
<span id="t3l_2" class="t v36_2 s4_2">(Dia/Mes/Año) </span> 
<span id="t3l_2_VENAFE" class="t v36_2 s4_2">{{data?.fec_ven_fa}}</span>
<span id="t3m_2" class="t v21_2 s2_2">Plaquetas </span>
<span id="t3n_2" class="t v21_2 s2_2">Glóbulos </span>
<span id="t3o_2" class="t v21_2 s2_2">Otro </span>
<span id="t3p_2" class="t v37_2 s4_2">Volumen </span>
<span id="t3q_2" class="t v37_2 s4_2">Colectado </span>
<span id="t3r_2" class="t v37_2 s4_2">(ml) </span>
<span id="t3r_2_COLFA" class="t v37_2 s4_2"> {{data?.vol_col}}</span>
<span id="t3s_2" class="t v20_2 s2_2">Recuento celular del donante antes del </span>
<span id="t3t_2" class="t v20_2 s2_2">procedimiento Plaquetas &gt; 150000 </span><span id="t3u_2" class="t v20_2 s2_2">pq/uL </span>
<span id="t3v_2" class="t v38_2 s2_2">Plaquetas x 10 /ul </span>
<span id="t3v_2_PLAQ" class="t v38_2 s2_2">{{data?.rec_mol}}</span>
<span id="t3w_2" class="t v39_2 sc_2">3 </span>
<span id="t3x_2" class="t v22_2 s2_2">Responsable Aféresis </span>
<span id="t3y_2" class="t v21_2 s2_2">SI </span><span id="t3y_2_XRADSI" class="t v21_2 s2_2">{{data?.reac_don == "SI" ? "X" : ""}}</span> <span id="t3z_2" class="t v21_2 s2_2">NO </span> <span id="t3z_2XRADNO" class="t v21_2 s2_2">{{data?.reac_don == "NO" ? "X" : ""}}</span>
<span id="t40_2" class="t v21_2 s4_2">Venopunción <span style="margin-left: 6%;">{{data?.tip_rad == 1 ? "X" : ""}}</span> </span>
<span id="t41_2" class="t v21_2 s4_2">Grado 1 <span style="margin-left: 11%;" >{{data?.severidad == 1 ? "X": ""}}</span></span>
<span id="t42_2" class="t v21_2 s4_2">Vasogaval <span style="margin-left: 10%;">{{data?.tip_rad == 2 ? "X" : ""}}</span></span>
<span id="t43_2" class="t v21_2 s1_2">Tipo de RAD </span>
<span id="t44_2" class="t v5_2 s4_2">Toxicidad por Citrato  <span style="margin-left: 2%;">{{data?.tip_rad == 3 ? "X" : ""}}</span></span>
<span id="t45_2" class="t v21_2 s2_2">¿Se recomendó al donante potencial consultar con su centro de atención en salud por alguna dolencia o factor de riesgo asociado con </span>
<span id="t46_2" class="t v21_2 s2_2">su estado de salud individual o que pudiera afectar a la salud pública? </span>
<span id="t47_2" class="t v21_2 s2_2">SI </span><span id="t48_2" class="t v21_2 s2_2">NO </span><span id="t49_2" class="t v40_2 s2_2" data-mappings='[[6,"fi"]]'>Especiﬁcar Causa:_____________________________________________________________________________ </span>
<span id="t4a_2" class="t v14_2 s2_2">Validado por: </span><span id="t4b_2" class="t v14_2 s2_2">Auditado por: </span>


<span id="obser" *ngIf="tipe == 1"  class="t v41_2 tam"> <span>{{data?.correcciones?.correccion}}</span> </span>
<span  id="firmaobser"  *ngIf="data?.correcciones.length > 0 && tipe == 1" class="t v41_2 tam"><img [src]="data?.firma_digital" alt="" width="60" > </span>

<span id="t4d_2" class="t v41_2 sd_2"> </span>


<span id="t4g_2" class="t v27_2 s9_2"> </span>
<span id="t4h_2" class="t v6_2 s1_2">IMC </span>
<span id="t4i_2" class="t v6_2 s1_2">( kg/m </span><span id="t4j_2" class="t v6_2 s1_2">) </span>
<span id="t4k_2" class="t v6_2 s1_2">&gt; 18.5 </span> <span *ngIf="tipe == 1" id="t4k_2_IMC" class="t v6_2 s1_2"> {{imc}} </span>
<span id="t4l_2" class="t v42_2 sb_2">2 </span>
<span id="t4m_2" class="t v43_2 s2_2">Voluntarios </span>
<span id="t4n_2" class="t v43_2 s2_2">Convocados </span> <span id="t4n_2_X" class="t v43_2 s2_2">{{data?.tipo_donante == 66 ? "X" : ""}}</span>
<span id="t4o_2" class="t v44_2 s3_2">Firma del Donante. </span>
<span *ngIf="tipe == 1" id="t4o_2_FIR" class="t v44_2 s3_2"><img  [src]="data?.firma_digital" alt="" width="60" > </span>
<span id="t4p_2" class="t v45_2 s4_2">Fecha de Donación. </span><span id="t4q_2" class="t v30_2 s3_2">Fecha y Firma Revisado de la encuesta </span>
<span id="t4r_2" class="t se_2">SOLO PARA PLAQUETAS POR AFERESIS </span>
<span id="t4s_2" class="t v5_2 s4_2">Otros <span style="margin-left: 45%;">{{data?.tip_rad == 4 ? "X" : ""}}</span></span>
<span id="t4t_2" class="t v21_2 s3_2">Severidad </span><span id="t4u_2" class="t v21_2 s4_2">Grado 2 <span style="margin-left: 13%;" >{{data?.severidad == 2 ? "X": ""}}</span> </span><span id="t4v_2" class="t v21_2 s4_2">Grado 3 <span style="margin-left: 11%;" >{{data?.severidad == 3 ? "X": ""}}</span></span><span id="t4w_2" class="t v21_2 s4_2">Grado 4 <span style="margin-left: 11%;" >{{data?.severidad == 4 ? "X": ""}}</span></span><span id="t4x_2" class="t v21_2 s4_2">Grado 5 <span style="margin-left: 11%;" >{{data?.severidad == 5 ? "X": ""}}</span>	</span></div>



</div>
</body>
</html>




