<!-- breadcrumb item -->
<div class="row">
  <div class="col-12">
    <div class="px-md-4 page-title-box d-flex align-items-center justify-content-end">
      <!-- <h4 class="mb-0 font-size-18">{{ title }}</h4> -->
      <div class="page-title-right">
        <ol class="breadcrumb m-0">
          <ng-container *ngFor="let item of breadcrumbItems">
            <li class="breadcrumb-item text-capitalize" *ngIf="!item.active"><a [routerLink]="['/',item.route,'list']" routerLinkActive="router-link-active" >{{ item.label }}</a>            </li>
            <li class="breadcrumb-item active" *ngIf="item.active">{{ item.label }}
            </li>
          </ng-container>
        </ol>
      </div>
    </div>
  </div>
</div>
