import { Injectable, Injector } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpClient,
  HttpHeaders
} from '@angular/common/http';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/mergeMap';
import { Observable, throwError } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {

  constructor(
    private injector: Injector,
    private authService: AuthService
  ) { }

  private getHeaders() {

    let token = localStorage.getItem('token');
    let headers_object = new HttpHeaders(
      {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + token || ''
      }
    )

    const httpOptions = {
      headers: headers_object
    };
    return httpOptions
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    return next.handle(request).catch(
      (errResp: HttpErrorResponse) => {
        // const error = (typeof errResp.error !== 'object') ? JSON.parse(errResp.error) : errResp.error;

        // if ((errResp.status == 401) && (error.renew)) {
        //   localStorage.setItem('token', error.token);
        //   const http = this.injector.get(HttpClient);
        //   const cloneRequest = request.clone({setHeaders: {'Authorization': `Bearer ${error.token}`}})
        //   return next.handle(cloneRequest);
        // }

        // if ((errResp.status == 401) && (!error.renew) && (error.renew != undefined)) {
        //   this.authService.logout();
        // }

        if (errResp.status == 401) {
          this.authService.logout();
        }

        return throwError(errResp);
      }
    );

  }
}
