import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { Page404Component } from './page404/page404.component';
import { Page403Component } from './page403/page403.component';
import { PageConnectionRefusedComponent } from './page-connection-refused/page-connection-refused.component';

const routes: Routes = [
    {
        path: '403',
        component: Page403Component
    },
    {
        path: '404',
        component: Page404Component
    },
    {
        path: 'error-no-server',
        component: PageConnectionRefusedComponent
    }
];


@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})

export class ExtrapagesRoutingModule { }
