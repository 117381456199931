import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class ConnectionRefusedInterceptor implements HttpInterceptor {

  constructor(private router: Router) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    
    return next.handle(request).pipe(catchError(err => {
      if (err.status === 0) {
        this.router.navigate(['/pages', 'error-no-server']);
      }

      if(err.status === 403) {
        this.router.navigate(['/pages', '403']);
      }

      return throwError(err);
    }));
  }
}
