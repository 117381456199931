import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {

  constructor(private authService: AuthService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    return this.authService.isLoggedIn
      .pipe(
        tap(valid => {
          if (!valid) {
            this.router.navigate(['/account/login'], { queryParams: { returnUrl: state.url } })
          }
        })
      )
  }
  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    return this.authService.isLoggedIn
      .pipe(
        tap(valid => {
          if (!valid) {
            this.router.navigate(['/account/login'], { queryParams: { returnUrl: state.url } })
          }
        })
      )
  }
  canLoad(): Observable<boolean> | boolean {

    return true;
  }
}
