import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
import { LanguageService } from '../../core/services/language.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/account/auth/services/auth.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})

/**
 * Topbar component
 */
export class TopbarComponent implements OnInit {

  element;
  cookieValue;
  flagvalue;
  countryName;
  valueset;

  private _isDisabled = false;

  form: FormGroup;



  constructor(
    @Inject(DOCUMENT) private document: any,
    private router: Router,
    // private authService: AuthenticationService,
    // private authFackservice: AuthfakeauthenticationService,
    private authService: AuthService,
    public languageService: LanguageService,
    public translate: TranslateService,
    public _cookiesService: CookieService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
  ) {
  }

  get nombreUsuario() {
    return this.authService.nombreUsuario;
  }

  get rolUsuario() {
    return this.authService.rolUsuario;
  }

  listLang = [
    { text: 'English', flag: 'assets/images/flags/us.jpg', lang: 'en' },
    { text: 'Spanish', flag: 'assets/images/flags/spain.jpg', lang: 'es' },
    { text: 'German', flag: 'assets/images/flags/germany.jpg', lang: 'de' },
    { text: 'Italian', flag: 'assets/images/flags/italy.jpg', lang: 'it' },
    { text: 'Russian', flag: 'assets/images/flags/russia.jpg', lang: 'ru' },
  ];

  openMobileMenu: boolean;

  @Output() settingsButtonClicked = new EventEmitter();
  @Output() mobileMenuButtonClicked = new EventEmitter();

  ngOnInit() {
    this.openMobileMenu = false;
    this.element = document.documentElement;


    this.form = this.formBuilder.group({
      passwd: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(14)]]
    });

    this.cookieValue = this._cookiesService.get('lang');
    const val = this.listLang.filter(x => x.lang === this.cookieValue);
    this.countryName = val.map(element => element.text);
    if (val.length === 0) {
      if (this.flagvalue === undefined) { this.valueset = 'assets/images/flags/us.jpg'; }
    } else {
      this.flagvalue = val.map(element => element.flag);
    }
  }

  setLanguage(text: string, lang: string, flag: string) {
    this.countryName = text;
    this.flagvalue = flag;
    this.cookieValue = lang;
    this.languageService.setLanguage(lang);
  }

  /**
   * Toggle the menu bar when having mobile screen
   */
  toggleMobileMenu(event: any) {
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
  }

  /**
   * Logout the user
   */
  logout() {
    this.authService.logout();
  }

  campoEsValido(campo: string) {
    return this.form.controls[campo].errors
      && this.form.controls[campo].touched;
  }

  /**
  * Open modal
  * @param content modal content
  */
  openModal(content: any) {
    this.modalService.open(content);
    this.form.controls['passwd'].reset('');
  }

  closeModal() {
    this.modalService.dismissAll();
    this.form.controls['passwd'].reset('');
    this._isDisabled = false;
  }

  /**
    * Tocar el formulario
    */
  private touchForm() {
    this.form.markAllAsTouched();
  }


  /**
   * Actualizar la contraseña
   */
  edit() {

    if (this.form.invalid) {
      this.touchForm();
      return;
    }

    this._isDisabled = true;

    this.authService.changePassword(this.form.value.passwd).subscribe(
      resp => {
        this.successmsg('Se editó con éxito');
        this.closeModal();
      },
      err => {
        this.errormsg('Ocurrió un error al editar la contraseña');
        this._isDisabled = false;
      }
    )
  }


  /**
   * Mostrar mensaje de éxito
   * 
   * @param msg string
   */
  private successmsg(msg: string) {
    Swal.fire("Exitoso", msg, "success");
  }

  /**
   * Mostrar mensaje de error
   *
   * @param msg string
   */
  private errormsg(msg: string) {
    Swal.fire({ title: "Espere!", text: msg, icon: "warning", iconColor: 'red', confirmButtonColor: 'red' });
  }

  public get isDisabled() {
    return this._isDisabled;
  }


}
