import { MenuItem } from './menu.model';

let PerMisoFormDona = localStorage.getItem('formD')

// let Menu:MenuItem[] =[];

let navigationItems:  MenuItem[] = [];

console.log(PerMisoFormDona)

if(PerMisoFormDona == "1"){
   navigationItems.push(
   
   )
}else if(PerMisoFormDona == "0"){
   navigationItems.push(
    {
                id: 124,
                label: 'MENUITEMS.ADMIN.TEXT',
                icon: 'bxs-user',
                subItems: [
                    {
                        id: 127,
                        label: 'MENUITEMS.ADMIN.LIST.ROLES.TEXT',
                        icon: 'bxs-user-detail',
                        parentId: 124,
                        link: '/roles',
                        permiso: 'role.index'
                    },
                    {
                        id: 126,
                        label: 'MENUITEMS.ADMIN.LIST.USERS.TEXT',
                        icon: 'bxs-user-plus',
                        parentId: 124,
                        link: '/users',
                        permiso: 'user.index',
                        rol: 'Usuarios', // Rol
                    },
                    {
                        id: 130,
                        label: 'MENUITEMS.ADMIN.LIST.CLIENTS.TEXT',
                        icon: 'bxs-user-pin',
                        parentId: 124,
                        link: '/clients',
                        permiso: 'client.index'
                    },
                    {
                        id: 131,
                        label: 'MENUITEMS.PLACES.TEXT',
                        icon: 'bx bx-map',
                        parentId: 124,
                        link: '/places',
                        permiso: 'place.index',
                    },
                    {
                        id: 134,
                        label: 'MENUITEMS.ADMIN.LIST.MEDICAMENTS.TEXT',
                        link: '/medicamentos',
                        parentId: 124,
                        icon: 'bx bx-band-aid',
                        permiso: 'medicine.index'
                    },
                    {
                        id: 135,
                        label: 'MENUITEMS.ADMIN.LIST.PARAMS.TEXT',
                        link: '/params/dias',
                        parentId: 124,
                        icon: 'bx-brush-alt',
                        permiso: 'donation.days.update'
                    },
                    {
                        id: 136,
                        label: 'MENUITEMS.ADMIN.LIST.DEFERRAL.TEXT',
                        link: '/params/diferimiento',
                        parentId: 124,
                        icon: 'bx bxs-hourglass-bottom',
                        permiso: 'deferral.index'
                    },
                    {
                        id: 137,
                        label: 'MENUITEMS.ADMIN.LIST.BAGS.TEXT',
                        link: '/params/bolsas',
                        parentId: 124,
                        icon: 'bx bx-droplet',
                        permiso: 'bag.index'
                    },
                    {
                        id: 138,
                        label: 'MENUITEMS.ADMIN.LIST.COMPONENTS.TEXT',
                        link: '/params/componentes',
                        parentId: 124,
                        icon: 'bx bx-cube-alt',
                        permiso: 'bag.index'
                    },
                    {
                        id: 140,
                        label: 'Otras Pruebas',
                        link: '/params/otras-pruebas',
                        parentId: 124,
                        icon: 'bx bx-cube-alt',
                        permiso: 'otrasPruebas.index'
                    },
                    {
                        id: 142,
                        label: 'Nevera',
                        link: '/params/nevera',
                        parentId: 124,
                        icon: 'bx bx-cube-alt',
                        permiso: 'nevera.index'
                    },
                    {
                        id: 141,
                        label: 'Estanteria',
                        link: '/params/estanderia',
                        parentId: 124,
                        icon: 'bx bx-cube-alt',
                        permiso: 'estanderia.index'
                    }
                ]
            },
            // {
            //     id: 160,
            //     label: 'MENUITEMS.DONATION.TEXT',
            //     icon: 'bx-add-to-queue',
            //     link: '/donacion/form',
            //     permiso: 'donation.form', // Permiso
            // },
            {
                id: 165,
                label: 'MENUITEMS.DONATION_VERIFY.TEXT',
                icon: 'bx-add-to-queue',
                link: '/donacion/verify',
                permiso: 'verify.index', // Permiso
            },
            {
                id: 170,
                label: 'MENUITEMS.DONATION_CONTINUE.TEXT',
                icon: 'bx bx-notepad',
                link: '/donacion/continue',
                permiso: 'donation.list', // Permiso
            },
            {
                id: 200,
                label: 'Reacciones adversas',
                icon: 'bx bx-notepad',
                link: '/donacion/reacciones',
                permiso: 'donation.list', // Permiso
            },
            {
                id: 201,
                label: 'Alicuotas',
                icon: 'bx bx-notepad',
                subItems: [
                    {
                        id:220,
                        label: 'Agregar',
                        link: '/donacion/alicuotas',
                        permiso: 'donation.list', // Permiso
                    },
                    {
                        id:221,
                        label: 'Listar',
                        link: '/donacion/alicuotas/lista',
                        permiso: 'donation.list', // Permiso
                    }
                ],
        
            },
            {
                id: 201,
                label: 'Conversion',
                icon: 'bx bx-notepad',
                subItems: [
                    {
                        id:230,
                        label:'Agregar',
                        link: '/donacion/conversion',
                        permiso: 'donation.list', // Permiso
                    },
                    {
                        id:240,
                        label:'Listar',
                        link: '/donacion/conversion/lista',
                        permiso: 'donation.list', // Permiso
                    }
                ]
            },
            {
                id: 180,
                label: 'MENUITEMS.DONATION_SEPARATION.TEXT',
                icon: 'bx bx-droplet',
                subItems: [
                    {
                        id: 181,
                        label: 'MENUITEMS.DONATION_SEPARATION.LIST.MANUAL',
                        icon: 'bx bx-cog',
                        parentId: 180,
                        link: '/donacion/separacion/manual',
                        permiso: 'separation.bag.index'
                    },
                    {
                        id: 182,
                        label: 'MENUITEMS.DONATION_SEPARATION.LIST.AUTOMATIC',
                        icon: 'bx bx-label',
                        parentId: 180,
                        link: '/donacion/separacion/automatica',
                        permiso: 'separation.bag.index',
                    },
                    // {
                    //     id: 183,
                    //     label: 'Fraccionamiento por unidad',
                    //     icon: 'bx bx-label',
                    //     parentId: 180,
                    //     link: '/donacion/separacionUnidad',
                    //     permiso: 'separation.bag.index',
                    // },
                    // {
                    //     id: 184,
                    //     label: 'Fraccionamiento crio',
                    //     icon: 'bx bx-label',
                    //     parentId: 180,
                    //     link: '/donacion/separacion/separacionCrio',
                    //     permiso: 'separation.bag.index',
                    // }
                ]
            },
            {
                id: 190,
                label: 'MENUITEMS.DONATION_LABORATORY.TEXT',
                icon: 'bx bxs-vial',
                subItems: [
                    {
                        id: 191,
                        label: 'MENUITEMS.DONATION_SEPARATION.LIST.MANUAL',
                        icon: 'bx bx-cog',
                        parentId: 190,
                        link: 'donacion/laboratorio/manual',
                        permiso: 'laboratory.index'
                    },
                    {
                        id: 192,
                        label: 'MENUITEMS.DONATION_SEPARATION.LIST.AUTOMATIC',
                        icon: 'bx bx-label',
                        parentId: 190,
                        link: 'donacion/laboratorio/automatico',
                        permiso: 'laboratory.index',
                    },
                    {
                        id: 193,
                        label: 'Confirmar serologia',
                        icon: 'bx bx-label',
                        parentId: 190,
                        link: 'donacion/laboratorio/confirmarSerologia',
                        permiso: 'laboratory.index',
                    }
                ]
            },
            {
                id: 200,
                label: 'MENUITEMS.DONATION_TAG.TEXT',
                icon: 'bx bx-purchase-tag',
                link: '/donacion/etiqueta',
                permiso: 'label.index'
            },
            {
                id: 210,
                label: 'MENUITEMS.STOCK.TEXT',
                icon: 'bx bx-clinic',
                subItems: [
                    {
                        id: 191,
                        label: 'MENUITEMS.STOCK.LIST.KARDEX',
                        icon: 'bx bx-task',
                        parentId: 210,
                        link: '/almacen/kardex',
                        permiso: 'kardex.index'
                    },
                    {
                        id: 192,
                        label: 'MENUITEMS.STOCK.LIST.TICKETS',
                        icon: 'bx bx-window-open',
                        parentId: 210,
                        link: '/almacen/entradas',
                        permiso: 'tickets.index',
                    },
                    {
                        id: 193,
                        label: 'MENUITEMS.STOCK.LIST.DEPARTURE_FORM',
                        icon: 'bx bx-exit',
                        parentId: 210,
                        link: '/almacen/salidas/form',
                        permiso: 'departures.store',
                    },
                    {
                        id: 194,
                        label: 'MENUITEMS.STOCK.LIST.DEPARTURE_LIST',
                        icon: 'bx bx-list-ol',
                        parentId: 210,
                        link: '/almacen/salidas/list',
                        permiso: 'departures.index',
                    },
                    {
                        id: 195,
                        label: 'MENUITEMS.STOCK.LIST.RESULT',
                        icon: 'bx bx-list-ol',
                        parentId: 210,
                        link: '/almacen/resultados',
                        permiso: 'resultado.index',
                    },
                    {
                        id: 195,
                        label: 'Entradas Externas',
                        icon: 'bx bx-list-ol',
                        parentId: 210,
                        link: '/almacen/entradasExternas',
                        permiso: 'departures.index',
                    },
                    // {
                    //     id: 195,
                    //     label: 'MENUITEMS.STOCK.LIST.TICKETS_SAVE',
                    //     icon: 'bx bx-list-ol',
                    //     parentId: 210,
                    //     link: '/almacen/entradas/save',
                    //     permiso: 'departures.index',
                    // },
                    {
                        id: 196,
                        label: 'MENUITEMS.STOCK.LIST.DROP',
                        icon: 'bx bx-list-ol',
                        parentId: 210,
                        link: '/almacen/bajas',
                        permiso: 'baja.store',
                    },
                    {
                        id: 197,
                        label: 'Seroteca',
                        icon: 'bx bx-list-ol',
                        parentId: 210,
                        link: '/almacen/seroteca',
                        permiso: 'seroteca.index',
                    },
                ]
            },
            {
                id: 220,
                label: 'Migración',
                icon: 'bx bx-clinic',
                subItems: [
                    {
                        id: 221,
                        label: 'Formulario',
                        icon: 'bx bx-task',
                        parentId: 220,
                        link: '/migracion/formulario',
                        permiso: 'migration.index'
                    },
                    {
                        id: 222,
                        label: 'Entrevista',
                        icon: 'bx bx-task',
                        parentId: 220,
                        link: '/migracion/entrevista',
                        permiso: 'migration.index'
                    },
                ]
            },
            {
                id: 160,
                label: 'Reportes',
                icon: 'bx-add-to-queue',
                link: 'reportes',
                permiso: 'donation.form', // Permiso
            },
            {
                id: 170,
                label: 'Trazabilidad',
                icon: 'bx-add-to-queue',
                link: 'trazabilidad',
                permiso: 'donation.form', // Permiso
            }
   )
}

export const MENU:MenuItem[] = navigationItems


// export const MENU: MenuItem[] = [
//     {
//         id: 124,
//         label: 'MENUITEMS.ADMIN.TEXT',
//         icon: 'bxs-user',
//         subItems: [
//             {
//                 id: 127,
//                 label: 'MENUITEMS.ADMIN.LIST.ROLES.TEXT',
//                 icon: 'bxs-user-detail',
//                 parentId: 124,
//                 link: '/roles',
//                 permiso: 'role.index'
//             },
//             {
//                 id: 126,
//                 label: 'MENUITEMS.ADMIN.LIST.USERS.TEXT',
//                 icon: 'bxs-user-plus',
//                 parentId: 124,
//                 link: '/users',
//                 permiso: 'user.index',
//                 rol: 'Usuarios', // Rol
//             },
//             {
//                 id: 130,
//                 label: 'MENUITEMS.ADMIN.LIST.CLIENTS.TEXT',
//                 icon: 'bxs-user-pin',
//                 parentId: 124,
//                 link: '/clients',
//                 permiso: 'client.index'
//             },
//             {
//                 id: 131,
//                 label: 'MENUITEMS.PLACES.TEXT',
//                 icon: 'bx bx-map',
//                 parentId: 124,
//                 link: '/places',
//                 permiso: 'place.index',
//             },
//             {
//                 id: 134,
//                 label: 'MENUITEMS.ADMIN.LIST.MEDICAMENTS.TEXT',
//                 link: '/medicamentos',
//                 parentId: 124,
//                 icon: 'bx bx-band-aid',
//                 permiso: 'medicine.index'
//             },
//             {
//                 id: 135,
//                 label: 'MENUITEMS.ADMIN.LIST.PARAMS.TEXT',
//                 link: '/params/dias',
//                 parentId: 124,
//                 icon: 'bx-brush-alt',
//                 permiso: 'donation.days.update'
//             },
//             {
//                 id: 136,
//                 label: 'MENUITEMS.ADMIN.LIST.DEFERRAL.TEXT',
//                 link: '/params/diferimiento',
//                 parentId: 124,
//                 icon: 'bx bxs-hourglass-bottom',
//                 permiso: 'deferral.index'
//             },
//             {
//                 id: 137,
//                 label: 'MENUITEMS.ADMIN.LIST.BAGS.TEXT',
//                 link: '/params/bolsas',
//                 parentId: 124,
//                 icon: 'bx bx-droplet',
//                 permiso: 'bag.index'
//             },
//             {
//                 id: 138,
//                 label: 'MENUITEMS.ADMIN.LIST.COMPONENTS.TEXT',
//                 link: '/params/componentes',
//                 parentId: 124,
//                 icon: 'bx bx-cube-alt',
//                 permiso: 'bag.index'
//             },
//             {
//                 id: 140,
//                 label: 'Otras Pruebas',
//                 link: '/params/otras-pruebas',
//                 parentId: 124,
//                 icon: 'bx bx-cube-alt',
//                 permiso: 'otrasPruebas.index'
//             },
//             {
//                 id: 142,
//                 label: 'Nevera',
//                 link: '/params/nevera',
//                 parentId: 124,
//                 icon: 'bx bx-cube-alt',
//                 permiso: 'nevera.index'
//             },
//             {
//                 id: 141,
//                 label: 'Estanteria',
//                 link: '/params/estanderia',
//                 parentId: 124,
//                 icon: 'bx bx-cube-alt',
//                 permiso: 'estanderia.index'
//             }
//         ]
//     },
//     {
//         id: 160,
//         label: 'MENUITEMS.DONATION.TEXT',
//         icon: 'bx-add-to-queue',
//         link: '/donacion/form',
//         permiso: 'donation.form', // Permiso
//     },
//     {
//         id: 165,
//         label: 'MENUITEMS.DONATION_VERIFY.TEXT',
//         icon: 'bx-add-to-queue',
//         link: '/donacion/verify',
//         permiso: 'verify.index', // Permiso
//     },
//     {
//         id: 170,
//         label: 'MENUITEMS.DONATION_CONTINUE.TEXT',
//         icon: 'bx bx-notepad',
//         link: '/donacion/continue',
//         permiso: 'donation.list', // Permiso
//     },
//     {
//         id: 200,
//         label: 'Reacciones adversas',
//         icon: 'bx bx-notepad',
//         link: '/donacion/reacciones',
//         permiso: 'donation.list', // Permiso
//     },
//     {
//         id: 201,
//         label: 'Alicuotas',
//         icon: 'bx bx-notepad',
//         subItems: [
//             {
//                 id:220,
//                 label: 'Agregar',
//                 link: '/donacion/alicuotas',
//                 permiso: 'donation.list', // Permiso
//             },
//             {
//                 id:221,
//                 label: 'Listar',
//                 link: '/donacion/alicuotas/lista',
//                 permiso: 'donation.list', // Permiso
//             }
//         ],

//     },
//     {
//         id: 201,
//         label: 'Conversion',
//         icon: 'bx bx-notepad',
//         subItems: [
//             {
//                 id:230,
//                 label:'Agregar',
//                 link: '/donacion/conversion',
//                 permiso: 'donation.list', // Permiso
//             },
//             {
//                 id:240,
//                 label:'Listar',
//                 link: '/donacion/conversion/lista',
//                 permiso: 'donation.list', // Permiso
//             }
//         ]
//     },
//     {
//         id: 180,
//         label: 'MENUITEMS.DONATION_SEPARATION.TEXT',
//         icon: 'bx bx-droplet',
//         subItems: [
//             {
//                 id: 181,
//                 label: 'MENUITEMS.DONATION_SEPARATION.LIST.MANUAL',
//                 icon: 'bx bx-cog',
//                 parentId: 180,
//                 link: '/donacion/separacion/manual',
//                 permiso: 'separation.bag.index'
//             },
//             {
//                 id: 182,
//                 label: 'MENUITEMS.DONATION_SEPARATION.LIST.AUTOMATIC',
//                 icon: 'bx bx-label',
//                 parentId: 180,
//                 link: '/donacion/separacion/automatica',
//                 permiso: 'separation.bag.index',
//             },
//             // {
//             //     id: 183,
//             //     label: 'Fraccionamiento por unidad',
//             //     icon: 'bx bx-label',
//             //     parentId: 180,
//             //     link: '/donacion/separacionUnidad',
//             //     permiso: 'separation.bag.index',
//             // },
//             // {
//             //     id: 184,
//             //     label: 'Fraccionamiento crio',
//             //     icon: 'bx bx-label',
//             //     parentId: 180,
//             //     link: '/donacion/separacion/separacionCrio',
//             //     permiso: 'separation.bag.index',
//             // }
//         ]
//     },
//     {
//         id: 190,
//         label: 'MENUITEMS.DONATION_LABORATORY.TEXT',
//         icon: 'bx bxs-vial',
//         subItems: [
//             {
//                 id: 191,
//                 label: 'MENUITEMS.DONATION_SEPARATION.LIST.MANUAL',
//                 icon: 'bx bx-cog',
//                 parentId: 190,
//                 link: 'donacion/laboratorio/manual',
//                 permiso: 'laboratory.index'
//             },
//             {
//                 id: 192,
//                 label: 'MENUITEMS.DONATION_SEPARATION.LIST.AUTOMATIC',
//                 icon: 'bx bx-label',
//                 parentId: 190,
//                 link: 'donacion/laboratorio/automatico',
//                 permiso: 'laboratory.index',
//             },
//             {
//                 id: 193,
//                 label: 'Confirmar serologia',
//                 icon: 'bx bx-label',
//                 parentId: 190,
//                 link: 'donacion/laboratorio/confirmarSerologia',
//                 permiso: 'laboratory.index',
//             }
//         ]
//     },
//     {
//         id: 200,
//         label: 'MENUITEMS.DONATION_TAG.TEXT',
//         icon: 'bx bx-purchase-tag',
//         link: '/donacion/etiqueta',
//         permiso: 'label.index'
//     },
//     {
//         id: 210,
//         label: 'MENUITEMS.STOCK.TEXT',
//         icon: 'bx bx-clinic',
//         subItems: [
//             {
//                 id: 191,
//                 label: 'MENUITEMS.STOCK.LIST.KARDEX',
//                 icon: 'bx bx-task',
//                 parentId: 210,
//                 link: '/almacen/kardex',
//                 permiso: 'kardex.index'
//             },
//             {
//                 id: 192,
//                 label: 'MENUITEMS.STOCK.LIST.TICKETS',
//                 icon: 'bx bx-window-open',
//                 parentId: 210,
//                 link: '/almacen/entradas',
//                 permiso: 'tickets.index',
//             },
//             {
//                 id: 193,
//                 label: 'MENUITEMS.STOCK.LIST.DEPARTURE_FORM',
//                 icon: 'bx bx-exit',
//                 parentId: 210,
//                 link: '/almacen/salidas/form',
//                 permiso: 'departures.store',
//             },
//             {
//                 id: 194,
//                 label: 'MENUITEMS.STOCK.LIST.DEPARTURE_LIST',
//                 icon: 'bx bx-list-ol',
//                 parentId: 210,
//                 link: '/almacen/salidas/list',
//                 permiso: 'departures.index',
//             },
//             {
//                 id: 195,
//                 label: 'MENUITEMS.STOCK.LIST.RESULT',
//                 icon: 'bx bx-list-ol',
//                 parentId: 210,
//                 link: '/almacen/resultados',
//                 permiso: 'resultado.index',
//             },
//             {
//                 id: 195,
//                 label: 'Entradas Externas',
//                 icon: 'bx bx-list-ol',
//                 parentId: 210,
//                 link: '/almacen/entradasExternas',
//                 permiso: 'departures.index',
//             },
//             // {
//             //     id: 195,
//             //     label: 'MENUITEMS.STOCK.LIST.TICKETS_SAVE',
//             //     icon: 'bx bx-list-ol',
//             //     parentId: 210,
//             //     link: '/almacen/entradas/save',
//             //     permiso: 'departures.index',
//             // },
//             {
//                 id: 196,
//                 label: 'MENUITEMS.STOCK.LIST.DROP',
//                 icon: 'bx bx-list-ol',
//                 parentId: 210,
//                 link: '/almacen/bajas',
//                 permiso: 'baja.store',
//             },
//             {
//                 id: 197,
//                 label: 'Seroteca',
//                 icon: 'bx bx-list-ol',
//                 parentId: 210,
//                 link: '/almacen/seroteca',
//                 permiso: 'seroteca.index',
//             },
//         ]
//     },
//     {
//         id: 220,
//         label: 'Migración',
//         icon: 'bx bx-clinic',
//         subItems: [
//             {
//                 id: 221,
//                 label: 'Formulario',
//                 icon: 'bx bx-task',
//                 parentId: 220,
//                 link: '/migracion/formulario',
//                 permiso: 'migration.index'
//             },
//             {
//                 id: 222,
//                 label: 'Entrevista',
//                 icon: 'bx bx-task',
//                 parentId: 220,
//                 link: '/migracion/entrevista',
//                 permiso: 'migration.index'
//             },
//         ]
//     },
//     {
//         id: 160,
//         label: 'Reportes',
//         icon: 'bx-add-to-queue',
//         link: 'reportes',
//         permiso: 'donation.form', // Permiso
//     },
//     {
//         id: 170,
//         label: 'Trazabilidad',
//         icon: 'bx-add-to-queue',
//         link: 'trazabilidad',
//         permiso: 'donation.form', // Permiso
//     }

// ];

