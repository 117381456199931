<header id="page-topbar">
  <div class="navbar-header">
    <div class="d-flex">
      <!-- LOGO -->
      <div class="navbar-brand-box">
        <a routerLink="/" class="logo logo-light">
          <!-- <span class="px-3">
            <img src="assets/images/logo-white.svg" alt="" style="width: 80%;">
          </span> -->

          <span class="logo-sm">
            <img class="text-white" src="assets/images/logo-white2.svg" alt="" height="22">
          </span>
          <span class="logo-lg">
            <!-- <img src="assets/images/logo-dark.png" alt="" height="17"> -->
            <img src="assets/images/logo-white.svg" alt="" style="width: 80%;">
          </span>

        </a>
      </div>

      <button type="button" class="btn btn-sm px-3 font-size-16 header-item" id="vertical-menu-btn"
        (click)="toggleMobileMenu($event)">
        <i class="fa fa-fw fa-bars"></i>
      </button>


    </div>

    <div class="d-flex">

      <div class="dropdown d-inline-block" ngbDropdown>
        <button type="button" class="btn header-item d-flex align-items-center justify-content-center" ngbDropdownToggle
          id="page-header-user-dropdown">
          <!-- <img class="rounded-circle header-profile-user" src="assets/images/users/avatar-1.jpg" alt="Header Avatar"> -->
          <i class="mdi mdi-account rounded-circle d-block" style="font-size: 25px;"></i>
          <span class="d-none d-xl-inline-block ms-1 text-uppercase">{{nombreUsuario}}</span>
          <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
          <a class="dropdown-item d-block" href="javascript: void(0);" (click)="openModal(content)"><i class="bx bx-wrench font-size-16 align-middle me-1"></i> Cambiar Contraseña
            </a>

          <a class="dropdown-item text-danger" href="javascript: void(0);" (click)="logout()"><i
              class="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i>
            Cerrar sesión</a>
          
        </div>
      </div>

    </div>
  </div>
</header>


<ng-template #content let-modal>
  <div class="modal-header">
    <h5 class="modal-title mt-0">Actualizar contraseña</h5>
    <button type="button" class="btn-close" (click)="modal.dismiss('Cross click')" aria-hidden="true"></button>
  </div>
  <div class="modal-body">
    <form [formGroup]="form" autocomplete="off" (ngSubmit)="edit()">

      <div class="mb-3">
        <label for="newPassword" class="form-label">Nueva contraseña</label>
        <input type="password" id="newPassword" class="form-control" placeholder="Ingrese nueva contraseña" name="passwd"
           formControlName="passwd">
        <span class="form-text text-danger" *ngIf="campoEsValido('passwd')">
          Mínimo 6 caracteres, máximo 14 caracteres
        </span>
      </div>

      <button class="btn btn-danger banco-color float-end" [disabled]="isDisabled" (click)="edit()">Editar</button>
    </form>
  </div>
</ng-template>