import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TrazaService } from '../service/traza.service';
import Swal from "sweetalert2";

@Component({
  selector: 'app-imp-encuesta',
  templateUrl: './imp-encuesta.component.html',
  styleUrls: ['./imp-encuesta.component.scss']
})
export class ImpEncuestaComponent implements OnInit {

  tipe = 0;
  codId = 0;
  nacimiento:any   
  data:any  = {}
  gruposan:any
  mestru:any
  fechDona:any
  firmaUrl: string;
  imc:any

  redes:any = []

  edad = 0

  dateObj:any = {}
  

  constructor(private _route:ActivatedRoute, private trazaService:TrazaService) { }

  ngOnInit(): void {

  


    this.tipe =  parseInt(this._route.snapshot.paramMap.get('tipe'));
    this.codId  = parseInt(this._route.snapshot.paramMap.get('codId')); 

    this.trazaService.descargarEncuesta(this.codId,this.tipe).subscribe( 
      resp  => {
        this.data = resp.data 

        let naci = this.data.fecha_nacimiento
        this.nacimiento = naci.split('-')
        this.gruposan = this.data.grupo_sanguineo.split("")
        this.redes  =  JSON.parse(this.data.redes_sociales) 
        this.mestru = this.data.fec_u_mest?.split('-')
        this.fechDona = this.data.fec_don?.split('-')

        let parse = parseFloat(this.data?.imc_kg_m2) 

        this.imc = parse.toFixed(2)

        console.log(this.data)

        let actual = new Date(this.data.fecha_registro_d);
        this.dateObj = {
          dia:actual.getDate(),
          mes:actual.getMonth()+1,
          anio:actual.getFullYear()
        }


        this.calculateEdad()
        
      },
      error => {
        this.errormsg(error.error.message)
      }
    )
    
  } 

  calculateEdad(){
    let fechaNacimiento = this.data.fecha_nacimiento
    let hoy = new Date();
    let fechaNac = new Date(fechaNacimiento);

    let edad = hoy.getFullYear() - fechaNac.getFullYear();
    let mes = hoy.getMonth() - fechaNac.getMonth();

    if (mes < 0 || (mes === 0 && hoy.getDate() < fechaNac.getDate())) {
      edad--;
    }

    this.edad  = edad

    

  }

  private errormsg(msg: string) {
    Swal.fire("Error!", msg, "error");
   }

  
  private successmsg(msg: string) {
    Swal.fire("Exitoso", msg, "success");
  }


}
