import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { getRole, RespuestaPermisos, RespuestaRoles, RoleNuevo } from 'src/app/core/interfaces/interfaces';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RoleServiceService {

  private _baseUrl: string = environment.baseUrl;

  constructor(private http: HttpClient) { }

  // Método utilizado para enviar las cabeceras de la petición
  get getHeaders() {

    const token = localStorage.getItem('token') || ''

    let headers_object = new HttpHeaders(
      {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + token || ''
      }
    )

    const httpOptions = {
      headers: headers_object
    };
    return httpOptions
  }

  roles(): Observable<RespuestaRoles> {
    return this.http.get<RespuestaRoles>(`${this._baseUrl}/roles`, this.getHeaders)
  }

  permisos(): Observable<any> {

    return this.http.get<any>(`${this._baseUrl}/permisos`, this.getHeaders)

  }

  // TODO: Corregir tipado y apiurl
  getMisPermisos(): Observable<any> {

    return this.http.get<any>(`${this._baseUrl}/mispermisos`, this.getHeaders)

  }

  addRole(body: RoleNuevo): Observable<any> {
    return this.http.post<any>(`${this._baseUrl}/roles`, body, this.getHeaders)
  }

  editRole(body: RoleNuevo): Observable<any> {
    return this.http.put<any>(`${this._baseUrl}/roles/${body.id}`, body, this.getHeaders)
  }

  getRole(id: number): Observable<any> {
    return this.http.get<any>(`${this._baseUrl}/roles/${id}`, this.getHeaders)
  }

  getPermissions() {
    return of(JSON.parse(atob(localStorage.getItem('permisos'))));
  }

  getPermissionByModulesss(name: string) {
    const data = JSON.parse(atob(localStorage.getItem('permisos')));

    return data.find(element => element.name == name);
  }

  /**
   * Retonar la lista de permisos que pertenecen a un módulo y al rol del usuario logueado
   * 
   * @param name string
   * @returns Observable<any>
   */
  public getPermissionsByModuleBackend(name: string): Observable<any> {
    return this.http.get<any>(`${this._baseUrl}/permisos/modulo/${name}`, this.getHeaders)
  }

  deleteRole(id: number): Observable<any> {
    return this.http.delete<any>(`${this._baseUrl}/roles/${id}`, this.getHeaders)
  }

  hasPermission(name: string): Observable<any> {
    return this.http.get<any>(`${this._baseUrl}/permission/${name}`, this.getHeaders)
  }
}
