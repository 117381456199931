import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService  implements OnInit {

 

  private _baseUrl: string = environment.baseUrl;

  private _isLoggedIn = new BehaviorSubject<boolean>(false);

  private _nombreUsuario: string = '';
  private _rolUsuario: string = '';

  returnUrl: string;

  constructor(
    private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute
    ) { }

    ngOnInit(): void {
      this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/dashboard';
    }

  /**
   * Retonar nombre del usuario logueado
   */
  get nombreUsuario() {
    //return (this._nombreUsuario.length > 5) ? this._nombreUsuario.slice(0, 10) + '...' : this._nombreUsuario;
    return localStorage.getItem('nombre') || '';
  }

  


  /**
   * Retornar rol del usuario logueado
   */
  get rolUsuario() {
    return localStorage.getItem('rol') || '';
  }

  private getHeaders(token: string) {

    let headers_object = new HttpHeaders(
      {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + token || ''
      }
    )

    const httpOptions = {
      headers: headers_object
    };
    return httpOptions
  }

  /**
   * Obtener el valor de si el usuario está logueado o no
   */
  get isLoggedIn(): Observable<boolean> {
    let flag = false;
    this._isLoggedIn.subscribe(
      resp => {
        if (resp) {
          flag = true;
        }
      }
    )

    if (localStorage.getItem('token') && !flag) {
      this._isLoggedIn.next(true);
    }

    return this._isLoggedIn.asObservable();
  }

  login(body: any): Observable<any> {
    const url = `${this._baseUrl}/auth/login`;
    return this.http.post<any>(url, { 'usuario': body.username, password: body.password })
  }

  changePassword(newPassword: string): Observable<any> {
    const url = `${this._baseUrl}/auth/change-password`;

    const headers = this.getHeaders(localStorage.getItem('token') || '')


    return this.http.post<any>(url, {'password': newPassword}, headers);
  }


  refreshToken(): Observable<boolean> {
    const url = `${this._baseUrl}/auth/refresh`;

    const headers = this.getHeaders(localStorage.getItem('token') || '')

    return this.http.post<any>(url, {}, headers)
      .pipe(
        map(
          resp => {
            localStorage.setItem('token', resp.access_token)
            this._nombreUsuario = localStorage.getItem('nombre')
            this._rolUsuario = localStorage.getItem('rol')
            return true;
          }
        ),
        catchError(
          err => {
            console.log(err);

            return of(false)
          }
        )
      );
  }

  logout() {
    const url = `${this._baseUrl}/auth/logout`;

    const headers = this.getHeaders(localStorage.getItem('token') || '')

    this._isLoggedIn.next(false);

    localStorage.removeItem('token');
    localStorage.removeItem('rol');
    localStorage.removeItem('nombre');

    this.http.post<any>(url, {}, headers).subscribe(
      resp => {
        this.router.navigateByUrl('/account/login').then(
          () => {
            window.location.reload();
          }
        )
      },
      error => {
        this.router.navigateByUrl('/account/login').then(
          () => {
            window.location.reload();
          }
        )
      }
    );
  }
}
