import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Headers } from 'src/app/core/helpers/params';

@Injectable({
  providedIn: 'root'
})
export class TrazaService {

  private _baseUrl: string = environment.baseUrl;

  constructor(private http:HttpClient) { }

  getDonacionTraza(body){
    const headers = Headers.getHeaders();
    return this.http.post<any>(`${this._baseUrl}/getTraza`,body,headers)
  }
  
  getDonacionAllData(cod){
    const headers = Headers.getHeaders();
    return this.http.get<any>(`${this._baseUrl}/getTrazaEntrevista/${cod}`,headers)
  }

  getSeparacionTraza(cod){
    const headers = Headers.getHeaders();
    return this.http.get<any>(`${this._baseUrl}/getDataTrazaSeparacion/${cod}`,headers)
  }

  getDataTrazaGrupo(cod){
    const headers = Headers.getHeaders();
    return this.http.get<any>(`${this._baseUrl}/getDataTrazaGrupos/${cod}`,headers)
  }

  getDataSerologia(cod){
    const headers = Headers.getHeaders();
    return this.http.get<any>(`${this._baseUrl}/getDataSerologia/${cod}`,headers)
  }

  putEntrevistaTraza(id,body){
    const headers = Headers.getHeaders();
    return this.http.put<any>(`${this._baseUrl}/actualizarEntrevistaTraza/${id}`,body,headers)
  }

  descargarEncuesta(codId,tipe){
    const headers = Headers.getHeaders();
    return this.http.get<any>(`${this._baseUrl}/getdata/encuImprimmir/${codId}/${tipe}`,headers)
  }
}
 